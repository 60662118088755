import { Autocomplete, Box, TextField } from "@mui/material";

const SearchBar = ({ handleSearchChange, options, value, disableClearable }) => {

  return (
    <Box sx={{ display: "flex" }}>
      <Autocomplete
        options={options.sort((a, b) => a.title.localeCompare(b.title))}
        sx={{ width: "50%", mt: 1, maxWidth: "500px", minWidth: "250px" }}
        value={value || null}
        renderInput={(params) => (
          <TextField {...params} label="Project" size="small" />
        )}
        getOptionLabel={(option) => option.title}
        onChange={handleSearchChange}
        isOptionEqualToValue={(option, value) => option.id === value.id }
        disableClearable={disableClearable}
      />
    </Box>
  );
};

export default SearchBar;
