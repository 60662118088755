import { useAppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import _ from "lodash";

import ItemCard from "../../Components/ItemCard/ItemCard";
import NextButton from "../../Components/NavButton/NextButton";
import Instruction from "../../Components/Instruction/Instruction";
import links from "../../Utils/links";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";
import GetWindowDim from "../../Utils/GetWindowDim";
import InstructionMobile from "../../Components/Instruction/InstructionMobile";
import { profileGender } from "../../Utils/profileGender";

const Profiles = () => {
  const {
    sections,
    setActiveProject,
    getProject,
    activeProjectId,
    projDetails,
    user,
    data,
    theme,
  } = useAppContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const gender = localStorage.getItem("gender");
  const sectionNum = localStorage.getItem("sectionNum");
  const type = localStorage.getItem("type");
  const profileView = JSON.parse(localStorage.getItem("profiles"));
  const isDesktop = GetWindowDim();
  const profileDetails = JSON.parse(localStorage.getItem("profileDetails"));
  const profileImg = JSON.parse(localStorage.getItem("profileImg"));

  const path =
    data[Number(sectionNum) + 1] !== undefined
      ? links.find((link) => link.id === sections[Number(sectionNum) + 1]).path
      : links.find((link) => link.id === 8).path;

  const filteredUser = user.projects.filter(
    (obj) => obj.projId === activeProjectId
  );

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId).then(() => {
        setIsLoading(false);
      });
    }
  }, [activeProjectId]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (type === "Rate" || type === "Rank") {
      navigate(links.find((link) => link.id === sections[sectionNum]).path);
    } else {
      localStorage.setItem(
        "sectionNum",
        Number(localStorage.getItem("sectionNum")) + 1
      );
      navigate(path);
    }
  };

  // console.log("PROFILE DETAILS", profileDetails)

  return (
    <div>
      <script>{(document.title = "Attractiveness")}</script>
      {isLoading ? (
        <div className={`background-${theme} center`}>
          <Loading />
        </div>
      ) : (
        <>
          {isDesktop ? (
            <Instruction type="attractive" />
          ) : (
            <InstructionMobile type="attractive" />
          )}
          <Grid container spacing={1} sx={{py: 2, alignItems: "stretch"}}>
            {_.map(profileDetails, (item, index) => {
              return (
                <Grid item key={index} xs={6} md={3}>
                  <ItemCard
                    id={index}
                    title={item.optionName}
                    img={profileImg[index]?.link}
                    description={item.description}
                    candidateCount={profileDetails?.length}
                    gender={
                      gender === "true"
                        ? profileGender(
                            filteredUser[0].sex,
                            projDetails.roles,
                            filteredUser[0].surveyRole
                          )
                        : "NA"
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box className="flexEnd">
            <NextButton
              isSurvey={true}
              text={type !== null ? type : "Next"}
              handleOnSubmit={handleOnSubmit}
              disabled={
                profileView === null || profileView?.length < profileDetails?.length
              }
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Profiles;
