import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";

import Instruction from "../../Components/Instruction/Instruction";
import NextButton from "../../Components/NavButton/NextButton";
import DragAndDrop from "../../Components/DragAndDrop/DragAndDrop";
import links from "../../Utils/links";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";

const Rank = () => {
  const {
    theme,
    updateUser,
    user,
    data,
    sections,
    setActiveProject,
    activeProjectId,
    getProject,
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  const sectionNum = localStorage.getItem("sectionNum");
  const [isLoading, setIsLoading] = useState(true);
  const profileDetails = JSON.parse(localStorage.getItem("profileDetails"));
  const profileImg = JSON.parse(localStorage.getItem("profileImg"));

  const filteredUser = user.projects.filter(
    (obj) => obj.projId === activeProjectId
  );

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId).then(() => {
        setIsLoading(false);
      });
    }
  }, [activeProjectId]);

  const path =
    data[Number(sectionNum) + 1] !== undefined
      ? links.find((link) => link.id === sections[Number(sectionNum) + 1]).path
      : links.find((link) => link.id === 8);

  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [rankItems, setRankItems] = useState([]);

  let arr = [];
  for (const [key, value] of Object.entries(profileDetails)) {
    value["_id"] = Number(key) + 1;
    value["link"] = profileImg?.[key]?.["link"];
    arr.push(value);
  }

  useEffect(() => {
    setAllItems(arr);
    setItems(arr);
  }, []);

  const rankDict = {};
  rankItems.map((item, index) => {
    const result = rankItems.indexOf(index + 1);
    rankDict[`option${index + 1}_rank`] = result + 1;
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    updateUser({
      currentUser: {
        ...user,
        rank: [...filteredUser[0].rank, rankItems],
        userResponse: {
          ...filteredUser[0].userResponse,
          [sectionNum]: rankDict,
        },
        projId: activeProjectId,
      },
      id: user._id,
    }).then(() => {
      localStorage.setItem(
        "sectionNum",
        Number(localStorage.getItem("sectionNum")) + 1
      );
      navigate(path);
    });
  };

  const handleViewProfile = (e) => {
    e.preventDefault();
    localStorage.setItem("type", "Rank");
    navigate("/profiles", {
      state: {
        link: location.pathname,
        type: "Rank",
      },
    });
  };

  return (
    <div>
      <script>{(document.title = "Profile Ranking")}</script>
      {isLoading ? (
        <div className={`background-${theme} center`}>
          <Loading />
        </div>
      ) : (
        <>
          <Box className="spaceBetween">
            <Box className="spaceBetween" sx={{ width: "250px" }}>
              <Instruction type="rank" />
              <Button
                variant="contained"
                className={`customButton-${theme}`}
                onClick={handleViewProfile}
              >
                View Profiles
              </Button>
            </Box>
            <Button
              variant="contained"
              className={`customButton-${theme}`}
              onClick={() => {
                setRankItems([]);
                setItems(allItems);
              }}
            >
              Reset Rank
            </Button>
          </Box>
          <DragAndDrop
            items={items}
            setItems={setItems}
            rankItems={rankItems}
            setRankItems={setRankItems}
            allItems={allItems}
          />
          <Box className="flexEnd" sx={{ mt: 3 }}>
            <NextButton
              disabled={rankItems.length < allItems.length}
              ratingType="rank"
              isSurvey={true}
              handleOnSubmit={handleOnSubmit}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Rank;
