import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";

import { Box, Button, Modal, Paper, Typography } from "@mui/material";

import { themePalette } from "../../Utils/themePalette";

const SaveChangeButton = ({ projectType, formData, templateNum, sectionNum }) => {
  const { updateProject, data, setIsChanged } = useAppContext();
  const [saveModal, setSaveModal] = useState(false);
  
  const projId = sessionStorage.getItem("projId");
  
  const navigate = useNavigate();
  const handleSubmit = async (formData) => {
    setIsChanged(false);
    setSaveModal(true)
    if (projectType === "projDetails") {
      sessionStorage.setItem("roles", JSON.stringify(formData["roles"]))
      await updateProject(projId, projectType, formData)
      .then(() => {
        sessionStorage.setItem("saveError", true);
        setSaveModal(false);
        navigate("/projects/summary");
      })
      .catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveModal(false);
      });
    }
    if (projectType === "projData") {
      let arr = data;
      let dict = {};
      dict[templateNum] = formData;
      if (Number(sectionNum) === arr.length + 1) {
        arr.push(dict);
      } else {
        arr[sectionNum - 1] = dict;
      }
      await updateProject(projId, projectType, arr)
      .then(() => {
        sessionStorage.setItem("saveError", true);
        setSaveModal(false);
        navigate("/projects/summary");
      })
      .catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveModal(false);
      });
    } else {
      await updateProject(projId, projectType, formData)
      .then(() => {
        sessionStorage.setItem("saveError", true);
        setSaveModal(false);
        navigate("/projects/summary");
      })
      .catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveModal(false);
      });
    }
  };

  useEffect(() => {
    if (saveModal === true) {
      document.body.style.cursor='wait';
    } 
    else {
      document.body.style.cursor='default'
    }
  }, [saveModal])

  return (
    <>
    <Box className="flexEnd" sx={{ my: 2 }}>
      <Button
        variant="contained"
        className="customButton-green"
        onClick={() => {
          handleSubmit(formData);
        }}
      >
        Save Changes
      </Button>
    </Box>
    <Modal 
      open={saveModal} 
      sx={{ '&  .MuiBackdrop-root': { background: "rgba(0, 0, 0, 0.2)" } }}
    >
      <Box 
        component={Paper} 
        sx={{
          position: 'absolute', 
          left: "50%",
          color: "white", 
          background: themePalette["green"]["primary"], 
          p: 1,
          px: 2,
          textAlign: "center",
        }}
      >
        <Typography>Saving changes in progress...</Typography>
      </Box>
    </Modal>
    </>
  );
};

export default SaveChangeButton;
