import { Alert, Button, Snackbar } from "@mui/material";
import { CgClose } from "react-icons/cg";

const SuccessAlert = ({ isSuccess, setIsSuccess, text, isSave }) => {

    const onClose = (event, reason) => {
        if (isSave || reason==="timeout") { sessionStorage.setItem("saveError", null) }; 
        setIsSuccess(null);
    }

    return(
        <Snackbar
            open={isSuccess}
            autoHideDuration={5000}
            onClose={onClose}
        >   
            <Alert severity="success">
                { text ? text : "Changes saved" }
                <Button
                    sx={{color: "#000000", minWidth:"10px", ml: 2}}
                    onClick={onClose}
                >
                    < CgClose style={{PointerEvent: "none"}}/>
                </Button>
            </Alert>
        </Snackbar>
    )
}

export default SuccessAlert;