import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { Box, Button, Typography } from "@mui/material";

import CustomStepper from "../Components/CustomStepper/CustomStepper";
import NextButton from "../Components/NavButton/NextButton";
import PrevButton from "../Components/NavButton/PrevButton";
import SaveChangeButton from "../Components/SaveButton/SaveChangeButton";
import SaveButton from "../Components/SaveButton/SaveButton";
import ErrorAlert from "../Components/SnackBar/ErrorAlert";
import SuccessAlert from "../Components/SnackBar/SuccessAlert";
import "./Layout.css";

const ProjectLayout = ({
  children,
  isEditing,
  subtitle,
  activeStep,
  nextLink,
  prevLink,
  projectType,
  formData,
  templateNum,
  sectionNum,
  nextDisabled,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const saveError = sessionStorage.getItem("saveError");
  const [isSuccess, setIsSuccess] = useState();
  
  const saveProject = () => {
    navigate("/projects");
    sessionStorage.clear();
  };

  useEffect(() => {
      document.body.style.cursor='default';
  }, [])

  useEffect(() => {
    setIsSuccess(saveError === "true");
}, [saveError])

  return (
    <>
      <Box sx={{ mx: 2 }}>
        {activeStep === 2 ? (
          <Box className="flexStart">
            <Typography variant="h5" className="projectHeader">
              REVIEW
            </Typography>
          </Box>
        ) : (
          <>
            <Typography variant="h5" className="projectHeader">
              {isEditing ? "Edit " : "Add "} Project
            </Typography>
            <Typography variant="subtitle2" className="projectHeader">
              {subtitle}
            </Typography>
          </>
        )}
      </Box>
      <CustomStepper activeStep={activeStep} />
      {children}
      {activeStep === 2 ? (
        <Box className="flexEnd" sx={{ pt: 3 }}>
          <Button
            variant="contained"
            className="customButton-green"
            onClick={saveProject}
          >
            Save Changes
          </Button>
        </Box>
      ) : (
        <>
          {isEditing ? (
            <SaveChangeButton
              projectType={projectType}
              formData={formData}
              templateNum={templateNum}
              sectionNum={sectionNum}
            />
          ) : (
            <Box className="spaceBetween">
              {location.pathname.includes("projects/details") || prevLink === "/projects/sections"
                  ? <Box/>
                  : <PrevButton link={prevLink} projectType={projectType} />
              }
              <Box display="flex" alignItems="center">
                <SaveButton
                  disabled={nextDisabled}
                  projectType={projectType}
                  formData={formData}
                  templateNum={templateNum}
                  sectionNum={sectionNum}
                  setIsSuccess={setIsSuccess}
                />
                <NextButton
                  disabled={nextDisabled}
                  link={nextLink}
                  projectType={projectType}
                  data={formData}
                  templateNum={templateNum}
                  sectionNum={sectionNum}
                />
              </Box>
            </Box>
          )}
        </>
      )}
      <SuccessAlert 
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        text="Changes saved successfully"
        isSave={true}
      />
      <ErrorAlert
        isError={saveError === "false"}
        text="Failed to save changes. Please try again later."
        isSave={true}
      />
    </>
  );
};

export default ProjectLayout;
