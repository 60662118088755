import { 
    Box, 
    Button,
    Dialog, 
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";

const PreviewDialog = ({ open, setOpen }) => {

    return(
        <Dialog open={open}>
            <DialogTitle 
                className="center"
                sx={{fontWeight:"bold", fontSize:"16px", color:"#264653"}}
            >
                Project published successfully 
            </DialogTitle>
            <DialogContent
                sx={{width: "350px"}}
            >
                <Typography sx={{textAlign:"center"}}>
                    View project preview?
                </Typography>
                <Box 
                    className="spaceBetween"
                    sx={{pt: 2, px: 4}}
                >
                    <Button
                        variant="outlined"
                        sx={{color: "#264653", borderColor:"#264653", textTransform:"none"}}
                        onClick={()=> setOpen(false)}
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        className="customButton-green"
                        onClick={() => {
                            window.open("/", '_blank')
                            setOpen(false)
                        }}
                        value={true}
                    >
                        Yes
                    </Button>
                </Box>
            </DialogContent>                        
        </Dialog>
    )
}

export default PreviewDialog; 