import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import axios from "axios";

import _ from "lodash";
import { Box, Typography } from "@mui/material";

import ParticipantTable from "../../Components/Tables/ParticipantTable";
import DeleteDialog from "../../Components/Dialog/DeleteDialog";
import ConfirmDialog from "../../Components/Dialog/ConfirmDialog";
import UploadParticipantDialog from "../../Components/Dialog/UploadParticipantDialog";
import SearchBar from "../../Components/SearchBar/SearchBar";
import SuccessAlert from "../../Components/SnackBar/SuccessAlert";
import ErrorAlert from "../../Components/SnackBar/ErrorAlert";
import { themePalette } from "../../Utils/themePalette"

const Participants = () => {
  const {
    setCreateProject,
    getProject,
    getAllProjects,
    projects,
    sendEmail,
    getUsersByProjId,
    users,
    deleteUsers,
    emailList,
  } = useAppContext();

  // persist data in session storage if user hits refresh
  const currentProjId = sessionStorage.getItem("pProjId");

  const [projectId, setProjectId] = useState(currentProjId);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [formData, setFormData] = useState(emailList);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState();
  const [sendLoading, setSendLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  // handle search bar
  const options = [];
  _.map(projects, (project) => {
    const { projDetails } = project;
    options.push({id: project._id, title: projDetails.title});
  });

  const handleSearchChange = async (event, value) => {
    if (value) {
      setProjectId(value.id);
      await handleOnClick(value.id); 
    }
  };

  const handleOnClick = async (projId) => {
    setIsLoading(true);
    setCreateProject(projId);
    sessionStorage.setItem("pProjId", projId);
    await getProject(projId);
    await getUsersByProjId(projId);
    setIsLoading(false);
  };

  // extract CSV
  const readCSV = async (id) => {
    try {
      const { data } = await axios.get(`/api/v1/projects/participants/${id}`);
      return data.data;
    } catch (error) {
      if (error.response.status !== 401) {
        console.log(error.response.data.msg);
      }
    }
  };

  // add participant
  const handleUpload = async () => {
    setIsLoading(true);
    const participants = await readCSV(projectId);
    const registerPromises = participants.map((element) => {
      return registerUser({
        name: element.Name,
        email: element.Email,
        password: "123456",
        projId: projectId,
      });
    });
    try {
      // Wait for all promises to resolve
      await Promise.all(registerPromises)
      await getUsersByProjId(projectId);
    } catch (error) {
      setMessage("Error - " + error.response.data.msg);
      setAlertOpen(true);
    }
    setFormData({ email: "", emailLink: "" });
    setIsLoading(false);
  };

  useEffect(() => {
    getAllProjects();
    if (projectId) {
      setCreateProject(projectId);
      getProject(projectId);
      getUsersByProjId(projectId);
    }
  }, []);

  useEffect(() => {
    getUsersByProjId(projectId);
  }, [isLoading]);

  useEffect(() => {
    setFormData(emailList);
  }, [emailList]);

  useEffect(() => {
    if (formData.email && formData.email.trim() !== "") {
      setIsFileUploaded(true);
    }
  }, [formData]);

  const registerUser = async (currentUser) => {
    return await axios.post("/api/v1/auth/register", currentUser);
  };

  // delete participant
  const handleDelete = async () => {
    setIsLoading(true);
    await Promise.all(
      rowsSelected.map((user) => deleteUsers(user, projectId))
    );
    setDeleteOpen(false);

    await getProject(projectId);
    await getUsersByProjId(projectId).then(() => {
      setAlertOpen(true);
      setMessage("Participant(s) deleted successfully");
    });
    setIsLoading(false);
  };

  // send email 
  const handleConfirm = async () => {
    const selectedUsers = rowsSelected.map((id) => {
      const user = users.find((user) => user._id === id);
      return user;
    });
    setSendLoading(true);
    setSendOpen(false);
    setRowsSelected([]);
    const promises = selectedUsers.map((user) => {
      const filteredUser = user.projects.filter(
        (obj) => obj.projId === projectId
      );
      return sendEmail({
        email: user.email,
        name: user.name,
        otp: filteredUser[0]?.otp,
        projId: filteredUser[0]?.projId,
        type: "start",
      });
    });
    try {
      await Promise.all(promises).then(() => {
        setMessage("Email(s) sent successfully");
      });
    } catch (error) {
      setMessage(`Error - Failed to send email`);
    }
    setAlertOpen(true);
    setSendLoading(false);
  };

  return (
    <Box m={2}>
      <script>{(document.title = "Participants")}</script>
      <Typography variant="h5" className="projectHeader">
        PARTICIPANTS
      </Typography>
      <Typography color={themePalette.green.secondary}>Please select a project using the search bar</Typography>
      <SearchBar
        handleSearchChange={handleSearchChange}
        value={options.filter((aOption) => aOption.id === projectId)[0]}
        options={options}
        disableClearable={true}
      />
      { projectId && 
      <>
        <ParticipantTable
          rowsSelected={rowsSelected}
          setRowsSelected={setRowsSelected}
          projectId={projectId?.split(":")[0]}
          setDeleteOpen={setDeleteOpen}
          setUploadOpen={setUploadOpen}
          setSendOpen={setSendOpen}
          isLoading={isLoading}
          sendLoading={sendLoading}
          setIsLoading={setIsLoading}
        />
        <DeleteDialog
          open={deleteOpen}
          setOpen={setDeleteOpen}
          handleDelete={handleDelete}
          id={rowsSelected.toString()}
          text={`The participant(s) (${rowsSelected.length} selected) will be permanently deleted from the storage`}
          header="Delete Participant(s)?"
        />
        <ConfirmDialog
          open={sendOpen}
          setOpen={setSendOpen}
          handleConfirm={handleConfirm}
          id={rowsSelected.toString()}
          text={`Are you sure you want to send the email to the selected participants (${rowsSelected.length} selected)?`}
          header="Send Email?"
        />
        <UploadParticipantDialog
          open={uploadOpen}
          setOpen={setUploadOpen}
          formData={formData}
          setFormData={setFormData}
          handleUpload={handleUpload}
          isFileUploaded={isFileUploaded}
        />                        
      </>
      }
      {alertOpen && message.includes("success") && (
        <SuccessAlert
          isSuccess={alertOpen}
          setIsSuccess={setAlertOpen}
          text={message}
        />
      )}
      {alertOpen && message.includes("Error") && (
        <ErrorAlert
          isError={alertOpen}
          setIsSuccess={setAlertOpen}
          text={message}
        />
      )}
    </Box>
  );
};

export default Participants;
