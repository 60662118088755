import { Alert, Button, Snackbar } from "@mui/material";
import { useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";

const ErrorAlert = ({ isError, text, isSave }) => {

    const [open, setOpen] = useState(isError);
    
    const onClose = (event, reason) => {
        if (isSave || reason==="timeout") { sessionStorage.setItem("saveError", null) };   
        setOpen(false);
    }

    useEffect(() => {
        setOpen(isError)
    }, [isError])

    return(
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={onClose}
        >   
            <Alert severity="error">
                { text ? text : "Error"}
                <Button
                    sx={{color: "#000000", minWidth:"10px", ml: 2}}
                    onClick={onClose}
                >
                    < CgClose style={{PointerEvent: "none"}}/>
                </Button>
            </Alert>
        </Snackbar>
    )
}

export default ErrorAlert;