import { useState, useEffect } from "react";

const GetWindowDim = () => {

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1200);
    };
        
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1200);

    return (isDesktop)
};


export default GetWindowDim;

