const profileGender = (userGender, allRole, surveyRole) => {
    
    let isGenderOpp = false;

    allRole.forEach((arr) => {
        if (Object.values(arr).includes(surveyRole)) {
            isGenderOpp = arr[arr.length - 1]
        }
    })

    if (!isGenderOpp) {
        return userGender.charAt(0).toUpperCase() + userGender.substr(1).toLowerCase();
    }

    if (userGender === "female" && isGenderOpp) {
        return "Male"
    } 
   
    return "Female"
}

export { profileGender} ;