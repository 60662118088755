import { useEffect, useState } from "react";
import { useAppContext } from "../../Context/AppContext";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import moment from "moment";
import _ from "lodash";

const ParticipantTable = ({
  rowsSelected,
  setRowsSelected,
  projectId,
  setDeleteOpen,
  setUploadOpen,
  setSendOpen,
  isLoading,
  sendLoading,
}) => {
  const [pageSize, setPageSize] = useState(5);
  const { users } = useAppContext();
  const today = moment(new Date()).format("DD-MM-YYYY");
  const [data, setData] = useState([]);

  const formatData = (users) => {
    const formattedData = [];
    _.forEach(users, (aUser) => {
      const currUser = {};
      currUser["id"] = aUser._id;
      currUser["name"] = aUser.name;
      currUser["email"] = aUser.email;

      for (let i = 0; i < aUser["projects"].length; i++) {
        if (aUser["projects"][i].projId === projectId) {
          const currProj = aUser["projects"][i];
          currUser["createdAt"] = currProj.createdDate;
          currUser["otp"] = currProj.otp;
          currUser["sex"] = currProj.sex;
          currUser["age"] = currProj.age;
          currUser["ethnicity"] = currProj.ethnicity;
          currUser["surveyRole"] = currProj.surveyRole;
          currUser["ipAddress"] = currProj.ipAddress;
          currUser["startTime"] = currProj.startTime;
          currUser["endTime"] = currProj.endTime;
          currUser["duration"] =
            currProj.startTime === undefined || currProj.endTime === undefined
              ? null
              : moment.duration(
                  moment(currProj.startTime).diff(moment(currProj.endTime))
                );
          currUser["completionCode"] = currProj.completionCode;
          break;
        }
      }
      formattedData.push(currUser);
    });
    return formattedData;
  };

  useEffect(() => {
    if (users) {
      setData(formatData(users));
    }
  }, [users]);

  const CustomToolBar = () => {
    return (
      <GridToolbarContainer className="spaceBetween" sx={{ mb: 1, p: 0 }}>
        <Box>
          <GridToolbarExport
            csvOptions={{
              allColumns: true,
              fileName: `${projectId} Participants (as of ${today})`,
            }}
            printOptions={{ disableToolbarButton: true }}
            sx={{
              backgroundColor: "#264653",
              color: "#FFFFFF",
              textTransform: "none",
              p: "8px 16px",
              "&:hover": {
                backgroundColor: "#C59D5F",
              },
              mr: 3,
            }}
          />
          <Button
            variant="contained"
            className="customButton-green"
            onClick={() => setUploadOpen(true)}
          >
            Add Participants
          </Button>
        </Box>
        <Box className="flexEnd">
          <Button
            variant="outlined"
            sx={{
              px: 3,
              mr: 3,
              color: "#264653",
              borderColor: "#264653",
              textTransform: "none",
            }}
            onClick={() => setDeleteOpen(true)}
            disabled={ rowsSelected.length === 0 }
          >
            Delete
          </Button>
          <Button
            variant="contained"
            className="customButton-green"
            onClick={() => setSendOpen(true)}
            disabled={sendLoading || rowsSelected.length === 0}
          >
            {sendLoading ? "Sending..." : "Send Email"}
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      components={{ Toolbar: CustomToolBar }}
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection
      onSelectionModelChange={(id) => setRowsSelected(id)}
      disableSelectionOnClick
      selectionModel={rowsSelected}
      rows={data}
      loading={isLoading}
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          align: "left",
          headerAlign: "left",
        },
        { field: "name", headerName: "Name" },
        { field: "email", headerName: "Email", width: 200 },
        {
          field: "createdAt",
          headerName: "Date Added",
          type: "date",
          valueFormatter: (params) =>
            params.value === undefined || params.value === null
              ? "null"
              : moment(params.value).format("DD/MM/YYYY hh:mm A"),
        },
        {
          field: "sex",
          headerName: "Sex",
          valueFormatter: (params) =>
            params.value === undefined ? "null" : params.value,
        },
        {
          field: "age",
          headerName: "Age",
          valueFormatter: (params) =>
            params.value === undefined ? "null" : params.value,
        },
        {
          field: "ethnicity",
          headerName: "Ethnicity",
          valueFormatter: (params) =>
            params.value === undefined ? "null" : params.value,
        },
        {
          field: "surveyRole",
          headerName: "Role",
          valueFormatter: (params) =>
            params.value === undefined ? "null" : params.value,
        },
        {
          field: "ipAddress",
          headerName: "IP Address",
          minWidth: 150,
          valueFormatter: (params) =>
            params.value === undefined || params.value === null
              ? "null"
              : params.value,
        },
        {
          field: "startTime",
          headerName: "Start",
          type: "dateTime",
          minWidth: 100,
          valueFormatter: (params) =>
            params.value === undefined || params.value === null
              ? "null"
              : moment(params.value).format("DD/MM/YYYY hh:mm A"),
        },
        {
          field: "endTime",
          headerName: "End",
          type: "dateTime",
          minWidth: 100,
          valueFormatter: (params) =>
            params.value === undefined || params.value === null
              ? "null"
              : moment(params.value).format("DD/MM/YYYY hh:mm A"),
        },
        {
          field: "duration",
          headerName: "Total Time Taken",
          type: "dateTime",
          minWidth: 100,
          valueFormatter: (params) => {
            try {
              const user = users.find((user) => user._id === params.id);
              const project = user.projects?.find(
                (proj) => proj.projId === projectId
              );
              const start = project?.startTime;
              const end = project?.endTime;
              if (start === undefined || end === undefined) return "null";
              const duration = moment.duration(moment(end).diff(moment(start)));
              return duration.humanize();
            } catch {
              return;
            }
          },
        },
        {
          field: "otp",
          headerName: "OTP",
          minWidth: 100,
        },
        {
          field: "completionCode",
          headerName: "Completion Code",
          minWidth: 150,
          valueFormatter: (params) =>
            params.value === undefined ? "null" : params.value,
        },
      ]}
      componentsProps={{
        Toolbar: {
          sx: {
            backgroundColor: "transparent",
          },
        },
      }}
      sx={{
        ".MuiDataGrid-columnHeaders": {
          backgroundColor: "#264653",
          color: "#FFFFFF",
          border: "solid 1px #E8E8E8",
        },
        ".MuiDataGrid-columnSeparator": {
          color: "transparent",
        },
        ".MuiDataGrid-cellContent": {
          overflowWrap: "normal",
          whiteSpace: "normal",
          justifyContent: "center",
        },
        ".MuiDataGrid-menuIcon": {
          color: "#FFFFFF",
        },
        ".MuiDataGrid-row": {
          border: "solid 1px #E8E8E8",
          backgroundColor: "#FFFFFF",
        },
        border: "transparent",
        mt: 2,
      }}
    />
  );
};

export default ParticipantTable;
