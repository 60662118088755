import { useAppContext } from "../../Context/AppContext";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import _ from "lodash";

import { themePalette } from "../../Utils/themePalette";

const RatingCard = ({ title, img, id, setRating, description, rateRange }) => {
  const { theme } = useAppContext();

  const range = !rateRange ? {
    lower: { number: 1, text: "Not interested at all" },
    upper: { number: 9, text: "Extremely interested" },
  }
  : rateRange;

  const handleOnChange = (event) => {
    setRating((state) => ({
      ...state,
      [String(id)]: event.target.value,
    }));
  };

  const defaultImg = require("../../Assets/Candidates/default_image.jpg");
  let imgLink = img === undefined  ? defaultImg : img; 

  return (
    <Card sx={{height: "100%"}}>
      <CardContent sx={{height: "100%", position: "relative"}}>
        <Typography
          className="cardHeader"
          sx={{ color: themePalette[theme]["primary"], height: "50px" }}
        >
          {title}
        </Typography>
        <Box className="imageBox">
          <img id={title} src={imgLink} alt="profile" />
        </Box>
        <Typography className="cardContent">{description}</Typography>
        <FormControl 
          fullWidth
          sx={{
            position: "absolute", 
            bottom: "24px", 
            left: "50%", 
            transform: "translateX(-50%)",
            px: 3
          }}
        >
          <TextField
            required
            fullWidth
            select
            id="rate"
            label="Rate"
            defaultValue=""
            onChange={handleOnChange}
          >
            {
            _.map(
              _.range(Number(range["lower"]["number"]), Number(range["upper"]["number"])+1),
              (num) => {
                return (
                  <MenuItem key={num} id={num} value={num}>
                    {num}
                    {Number(num) === Number(range["lower"]["number"])
                      ? ` (${range["lower"]["text"]})`
                      : Number(num) === Number(range["upper"]["number"])
                      ? ` (${range["upper"]["text"]})`
                      : ""}
                  </MenuItem>
                );
              }
            )
            }
          </TextField>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default RatingCard;
