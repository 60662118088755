import { useState } from "react";
import { useAppContext } from "../../Context/AppContext";

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

import PrevButton from "../../Components/NavButton/PrevButton";
import { themePalette } from "../../Utils/themePalette";

const ForgetPassword = () => {
  const { theme, forgetPassword, user, sendEmail } = useAppContext();
  const [formData, setFormData] = useState("");
  const [success, setSuccess] = useState(null);

  const handleOnSubmit = async () => {
    await forgetPassword({
      currentUser: {
        ...user,
        formData,
      },
    }).then(async (response) => {
      console.log(response)
      if (response.status === 400 || response.status === 401) {
        setSuccess({
          isSuccess: false, 
          message: "Email does not exist in our server."
        });
      } else {
        const { tempPassword, userAlreadyExists } = response;
        await sendEmail({
          email: userAlreadyExists.email,
          name: userAlreadyExists.name,
          tempPassword,
          type: "forgetPassword",
        }).then((res) => {
          if (res.status === 200 || res.status === 201) {
            setSuccess({ isSuccess: true })
          } else {
            setSuccess({
              isSuccess: false, 
              message: "Could not send new password. Please try again later."
            });
          }
        }).catch(() => {
          setSuccess({
            isSuccess: false, 
            message: "Could not send new password. Please try again later."
          });
        })
      }
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    });
  };

  return (
    <div className={`backgroundImage-${theme}`}>
      <script>{(document.title = "Forget Password")}</script>
      <Box pt={3} pl={3}>
        <PrevButton isSurvey={true} text="Back" link="/aLogin" />
        <Card sx={{ px: 1, py: 2, m: "auto", mt: 4, width: 500 }}>
          <CardContent className="flexColumn center">
            <Typography
              variant="h5"
              className="formCardHeader"
              sx={{
                color: themePalette[theme]?.["primary"],
                "&.MuiTypography-root": { mb: 1 },
              }}
            >
              Forgot your password?
            </Typography>
            <Typography
              sx={{
                color: themePalette[theme]?.["secondary"],
                fontSize: "14px",
                textAlign: "center",
                width: "80%",
                mb: 2,
              }}
            >
              Enter the email address associated with your account and we'll
              send you a temporary password.
            </Typography>
            <FormControl sx={{ width: "80%", mt: 2, mb: 4 }}>
              <TextField
                required
                type="email"
                name="email"
                label="Email"
                onChange={(event) => setFormData(event.target.value)}
              />
              {success !== null && (
                <Alert severity={success["isSuccess"] ? "success" : "error"}>
                  {success["isSuccess"]
                    ? "The new password has been sent to your email"
                    : success["message"]}
                </Alert>
              )}
            </FormControl>
            <Button
              disabled={formData === ""}
              variant="contained"
              type="submit"
              className={`customButton-${theme}`}
              onClick={handleOnSubmit}
            >
              Submit
            </Button>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default ForgetPassword;
