import { 
    Box, 
    Typography 
} from "@mui/material";

import { themePalette } from "../../Utils/themePalette";
import LoadingAnimation from "./LoadingAnimation";

const HomeLoading = () => {
    return(
        <Box className="center flexColumn" >
            <Typography
                color={themePalette["green"]["primary"]}
                sx={{mb: 3}}
            >
                Please wait a moment while we load the page...
            </Typography>
            <LoadingAnimation />
        </Box>
    )
}

export default HomeLoading;