import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import ChatTemplate from "../../Components/Chatbox/ChatTemplate";
import NextButton from "../../Components/NavButton/NextButton";
import Instruction from "../../Components/Instruction/Instruction";
import links from "../../Utils/links";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";
import GetWindowDim from "../../Utils/GetWindowDim";
import InstructionMobile from "../../Components/Instruction/InstructionMobile";

const Chat = ({ title, link }) => {
  const {
    updateUser,
    data,
    sections,
    user,
    setActiveProject,
    activeProjectId,
    getProject,
    theme,
  } = useAppContext();
  const navigate = useNavigate();

  const [selectMessage, setSelectMessage] = useState(null);
  const [rankToDisplay, setRankToDisplay] = useState(0);
  const profileDetails = JSON.parse(localStorage.getItem("profileDetails"));
  const profileImg = JSON.parse(localStorage.getItem("profileImg"));

  const sectionNum = Number(localStorage.getItem("sectionNum"));
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = GetWindowDim();

  const filteredUser = user.projects.filter(
    (obj) => obj.projId === activeProjectId
  );

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId).then((proj) => {
        const { data } = proj;
        let arrOfRank = [];

        // find how many rank
        for (const [sectionNum, dict] of Object.entries(data)) {
          // eslint-disable-next-line
          for (const [templateNo, data] of Object.entries(dict)) {
            if (templateNo === 3) {
              arrOfRank.push(Number(sectionNum));
            }
          }
        }

        // find which rank to display
        for (let i = 0; i < arrOfRank.length; i++) {
          const element = arrOfRank[i];
          if (element < sectionNum) {
            setRankToDisplay(i);
          }
        }
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [activeProjectId]);

  const path =
    data[Number(sectionNum) + 1] !== undefined
      ? links.find((link) => link.id === sections[Number(sectionNum) + 1]).path
      : links.find((link) => link.id === 8).path;

  const firstCandidate = Number(filteredUser[0]?.rank[rankToDisplay][0]) - 1;
  const lastCandidate =
    Number(
      filteredUser[0]?.rank[rankToDisplay][
        filteredUser[0]?.rank[rankToDisplay].length - 1
      ]
    ) - 1;

  let arr = [];
  for (const [key, value] of Object.entries(profileDetails)) {
    value["_id"] = Number(key) + 1;
    value["link"] = profileImg?.[key]?.["link"];
    arr.push(value);
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    updateUser({
      currentUser: {
        ...user,
        rank: [...filteredUser[0].rank],
        userResponse: {
          ...filteredUser[0].userResponse,
          [title === "1" ? `best_${sectionNum}` : `worst_${sectionNum}`]:
            selectMessage,
        },
        projId: activeProjectId,
      },
      id: user._id,
    }).then(() => {
      if (title === "1") {
        navigate(link);
      } else {
        localStorage.setItem(
          "sectionNum",
          Number(localStorage.getItem("sectionNum")) + 1
        );
        navigate(path);
      }
    });
  };

  return (
    <div>
      {isLoading ? (
        <div className={`background-${theme} center`}>
          <Loading />
        </div>
      ) : (
        <>
          <Box sx={{ ml: 5, mb: 2 }}>
            {isDesktop ? (
              <Instruction type="prewritten" />
            ) : (
              <InstructionMobile type="prewritten" />
            )}
          </Box>
          <ChatTemplate
            link={link}
            selectMessage={selectMessage}
            setSelectMessage={setSelectMessage}
            firstCandidate={arr[firstCandidate]}
            lastCandidate={arr[lastCandidate]}
            title={title}
          />
          <Box className="flexEnd" sx={{ mx: 5, my: 3 }}>
            <NextButton
              isSurvey={true}
              disabled={selectMessage === null}
              handleOnSubmit={handleOnSubmit}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Chat;
