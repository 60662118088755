import { useAppContext } from "../../Context/AppContext";

import { Box, Typography, CardHeader } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { RxMagnifyingGlass } from "react-icons/rx";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { themePalette } from "../../Utils/themePalette";

const ProfileInfo = ({ receiver, firstCandidate, lastCandidate }) => {
  const { theme } = useAppContext();
  const defaultImg = require("../../Assets/Candidates/default_image.jpg");

  return (
    <Box>
      <CardHeader
        sx={{
          backgroundColor: themePalette[theme]["primary"],
          color: "#FFFFFF",
          py: "12px",
        }}
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RxCross2 />
            <Box sx={{ width: "100%", display:"flex", justifyContent:"center"}}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Profile Info
              </Typography>
            </Box>
            <Box className="center" sx={{display:{sm:"none", xs:"flex"}}}>
              <RxMagnifyingGlass style={{ marginRight: 3 }} />
              <BiDotsVerticalRounded />
            </Box>
          </Box>
        }
      />
      <Box sx={{ p: 2 }}>
        <Box className="center">
          <img
            src={firstCandidate?.link === undefined || lastCandidate?.link === undefined ? defaultImg :
              receiver.includes("1")
                ? firstCandidate?.link
                : lastCandidate?.link
            }
            style={{ width: "80%" }}
            alt="candidate profile"
          />
        </Box>
        <Typography
          className="cardHeader"
          sx={{ color: themePalette[theme]["primary"] }}
        >
          {receiver.includes("1")
            ? firstCandidate?.optionName.toUpperCase()
            : lastCandidate?.optionName.toUpperCase()}
        </Typography>
        <Typography sx={{ fontSize: "14px", textAlign: "center", pt: 1 }}>
          {receiver.includes("1")
            ? firstCandidate?.description
            : lastCandidate?.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileInfo;
