import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import ProjectTable from "../../Components/Tables/ProjectTable";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";
import SuccessAlert from "../../Components/SnackBar/SuccessAlert";
import ErrorAlert from "../../Components/SnackBar/ErrorAlert";
import SearchBar from "../../Components/SearchBar/SearchBar";
import _ from "lodash";
import PreviewDialog from "../../Components/Dialog/PreviewDialog";

const Projects = () => {
  const navigate = useNavigate();
  const { getAllProjects, projects, createProject, setOriginalState, user } = useAppContext();

  const [deleteSuccess, setDeleteSuccess] = useState(null);
  const [filterProj, setFilterProj] = useState(projects);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currProj, setCurrProj] = useState(null);

  // handle Search bar
  const options = [];
  _.map(projects, (project) => {
    const { projDetails } = project;
    options.push({id: project._id, title: projDetails.title});
  });

  const handleSearchChange = (event, value) => {
    if (value) {
     setFilterProj(projects.filter((aProject) => aProject["_id"] === value.id));
     setCurrProj(options.filter((aOption) => aOption.id === value.id)[0]);
    } else {
      setFilterProj(projects);
      setCurrProj(null);
    }
  }

  // handle table interaction
  const handleOnClick = async () => {
    setIsLoading(true);
    const createdProjectId = await createProject(user);
    navigate("/projects/details");
    sessionStorage.setItem("editMode", "add");
    sessionStorage.setItem("projId", createdProjectId);
    sessionStorage.setItem("sectionNum", 0);
    sessionStorage.setItem("templates", []);
    sessionStorage.setItem("sections", []);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllProjects();
    setOriginalState();
  }, []);

  useEffect(() => {
    _.map(
      [
        "role",
        "gender",
        "profileImg",
        "profiles",
        "duration",
        "sectionNum",
        "filteredUser",
        "profileDetails",
        "type",
        "randomAudioIntro",
      ],
      (item) => {
        localStorage.removeItem(item);
      }
    );
  }, [projects]);

  useEffect(() => {
    setFilterProj(projects);
  }, [projects]);

  return (
    <Box m={2}>
      <script>{(document.title = "Projects")}</script>
      <Typography variant="h5" className="projectHeader">
        PROJECTS
      </Typography>
      {isLoading ? (
        <div className={`background-blue center`}>
          <Loading />
        </div>
      ) : (
        <>
          <Box className="flexStart flexColumn">
            <SearchBar
              handleSearchChange={handleSearchChange}
              options={options}
              disableClearable={false}
              value={currProj}
            />
            <Button
              variant="contained"
              onClick={handleOnClick}
              className="customButton-green"
              sx={{ width: "130px", mt: 2 }}
            >
              Add Project
            </Button>
          </Box>
          <ProjectTable
            data={filterProj}
            setDeleteSuccess={setDeleteSuccess}
            setIsLoading={setIsLoading}
            setPreviewOpen={setOpen}
          />
        </>
      )}
      <SuccessAlert
        isSuccess={deleteSuccess}
        setIsSuccess={setDeleteSuccess}
        text="Project deleted successfully"
      />
      <ErrorAlert
        isError={deleteSuccess===false}
        text="Project failed to delete. Please try again later."
      />
      <PreviewDialog open={open} setOpen={setOpen} />
    </Box>
  );
};

export default Projects;
