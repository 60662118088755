import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";

import { Box, Button, Card, CardContent, Typography } from "@mui/material";

import "./ItemCard.css";
import { themePalette } from "../../Utils/themePalette";

const ItemCard = ({ title, img, id, candidateCount, description, link }) => {
  const { theme } = useAppContext();
  const navigate = useNavigate();
  const handleOnClick = (event) => {
    navigate(`/profiles/${parseInt(event.target.id) + 1}`, {
      state: {
        id: `${event.target.id}`,
        candidateCount: { candidateCount },
        link: link,
      },
    });
  };
  const defaultImg = require("../../Assets/Candidates/default_image.jpg");
  let imgLink = img === undefined  ? defaultImg : img; 

  return (
    <Card sx={{height: "100%"}}>
      <CardContent sx={{height: "100%", position: "relative"}}>
        <Typography
          className="cardHeader"
          sx={{ color: themePalette[theme]["primary"], height: "50px" }}
        >
          {title}
        </Typography>
        <Box className="imageBox">
          <img id={title} src={imgLink} alt="profile" />
        </Box>
        <Typography className="cardContent">{description}</Typography>
        <Box sx={{
            position: "absolute", 
            bottom: "24px", 
            left: "50%", 
            transform: "translateX(-50%)"
          }}
        > 
          <Button
            variant="contained"
            onClick={handleOnClick}
            id={id}
            className={`customButton-${theme}`}
          >
            View Profile
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ItemCard;
