import { useAppContext } from "../../Context/AppContext";

import { Box, Typography, Button } from "@mui/material";
import { HiArrowLeft } from "react-icons/hi";


const ErrorPage = ({ resetErrorBoundary }) => {
  const { theme } = useAppContext();

  return (
    <div className={`backgroundImage-${theme} center`}>
      <Box
        className="center"
        sx={{
          height: "100vh",
          flexDirection: "column",
          py: 5,
        }}
      >
        <img 
          src={require("../../Assets/error404.png")}
          height="50%"
          alt="Image by pch.vector on Freepik"
        />
        <Typography variant="h3" my={1}>Oops! Something's wrong.</Typography>
        <Typography variant="h6" mb={3}>Please try again in a moment. If the problem continues, contact the administrator</Typography>
        <Button
            onClick={ resetErrorBoundary }
            variant="contained"
            className={`customButton-${theme}`}
        >
        <HiArrowLeft style={{ marginRight: "10px" }} />{" "}
        Return to Home
      </Button>
      </Box>
    </div>
  );
};

export default ErrorPage;

