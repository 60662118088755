import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Button, Grid } from "@mui/material";
import _ from "lodash";

import RatingCard from "../../Components/SurveyForm/RatingCard";
import NextButton from "../../Components/NavButton/NextButton";
import { isValid } from "../../Utils/isValid";
import Instruction from "../../Components/Instruction/Instruction";
import links from "../../Utils/links";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";
import GetWindowDim from "../../Utils/GetWindowDim";
import InstructionMobile from "../../Components/Instruction/InstructionMobile";

const AttractiveRate = () => {
  const {
    updateUser,
    user,
    theme,
    sections,
    data,
    setActiveProject,
    activeProjectId,
    getProject,
  } = useAppContext();
  const [rating, setRating] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const sectionNum = localStorage.getItem("sectionNum");
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = GetWindowDim();
  const profileDetails = JSON.parse(localStorage.getItem("profileDetails"));
  const profileImg = JSON.parse(localStorage.getItem("profileImg"));

  const filteredUser = user.projects.filter(
    (obj) => obj.projId === activeProjectId
  );

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId).then(() => {
        setIsLoading(false);
      });
    }
  }, [activeProjectId]);

  const path =
    data[Number(sectionNum) + 1] !== undefined
      ? links.find((link) => link.id === sections[Number(sectionNum) + 1]).path
      : links.find((link) => link.id === 8).path;

  const handleViewProfile = (e) => {
    e.preventDefault();
    localStorage.setItem("type", "Rate");
    navigate("/profiles", {
      state: {
        link: location.pathname,
        type: "Rate",
      },
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    updateUser({
      currentUser: {
        ...user,
        userResponse: {
          ...filteredUser[0].userResponse,
          [sectionNum]: rating,
        },
        rank: [...filteredUser[0].rank],
        projId: activeProjectId,
      },
      id: user._id,
    }).then((data) => {
      localStorage.setItem(
        "sectionNum",
        Number(localStorage.getItem("sectionNum")) + 1
      );
      navigate(path);
    });
  };

  return (
    <div>
      <script>{(document.title = "Profile Rating")}</script>
      {isLoading ? (
        <div className={`background-${theme} center`}>
          <Loading />
        </div>
      ) : (
        <>
          <Box className="spaceBetween" sx={{ width: "250px" }}>
            {isDesktop ? (
              <Instruction type="attractive" />
            ) : (
              <InstructionMobile type="attractive" />
            )}
            <Button
              variant="contained"
              className={`customButton-${theme}`}
              onClick={handleViewProfile}
            >
              View Profiles
            </Button>
          </Box>
          <Grid container spacing={1} sx={{ py: 2, alignItems: "stretch" }}>
            {_.map(profileDetails, (item, index) => {
              return (
                <Grid item key={index} xs={6} md={3}>
                  <RatingCard
                    id={`option${index + 1}_rate`}
                    title={item.optionName}
                    img={profileImg[index]?.link}
                    description={item.description}
                    setRating={setRating}
                    rateRange={data[sectionNum][sections[sectionNum]][filteredUser[0].surveyRole]?.range}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box className="flexEnd">
            <NextButton
              isSurvey={true}
              disabled={!isValid(rating, profileDetails.length)}
              handleOnSubmit={handleOnSubmit}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default AttractiveRate;
