import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";

import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import { HiArrowRight } from "react-icons/hi";
import { themePalette } from "../../Utils/themePalette";

const NextButton = ({
  isSurvey,
  link,
  state,
  text,
  style,
  disabled,
  storeItem,
  ratingType,
  handleOnSubmit,
  data,
  projectType,
  templateNum,
  setOpen,
  handleUpload,
}) => {
  const {
    theme,
    updateProject,
    projId,
    getProject,
    nextSection,
    sectionNum,
    setIsChanged
  } = useAppContext();

  const navigate = useNavigate();
  const [saveModal, setSaveModal] = useState(false);
  // ensure projectId do not become null when refresh
  const projectId = projId ? projId : sessionStorage.getItem("projId");
  
  const handleOnClick = async () => {
    sessionStorage.setItem("saveError", null);
    setIsChanged(false);
    if (ratingType === "rank") {
      sessionStorage.setItem("rank", storeItem);
    }

    if (projectType === "emailList") {
      await updateProject(projectId, projectType, data).then(() => {
        getProject(projectId);
        link && navigate(`${link}`, {state: { state }});
      })
      setOpen(false);
      handleUpload();
    } else if (projectType === "projDetails") {
      setSaveModal(true)
      sessionStorage.setItem("roles", JSON.stringify(data.roles));
      await updateProject(projectId, projectType, data).then(() => {
        getProject(projectId);
        link && navigate(`${link}`, {state: { state }});
        setSaveModal(false);
        sessionStorage.setItem("saveError", true);
      }).catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveModal(false);
      });
    } else if (projectType === "projData") {
      setSaveModal(true)
      nextSection()
      sessionStorage.setItem("sectionNum", Number(sessionStorage.getItem("sectionNum"))+1);
      let arr = JSON.parse(sessionStorage.getItem("projData"))
        ? JSON.parse(sessionStorage.getItem("projData"))
        : [];
      let dict = {};
      dict[templateNum] = data;
      console.log(sectionNum)
      if (sectionNum === arr.length) {
        arr.push(dict);
      } else {
        arr[sectionNum] = dict;
      }
      await updateProject(projectId, projectType, arr).then(() => {
        getProject(projectId);
        sessionStorage.setItem(projectType, JSON.stringify(arr));
        link && navigate(`${link}`, {state: { state }});
        setSaveModal(false)
        sessionStorage.setItem("saveError", true);
      }).catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveModal(false);
      });
    } else if (projectType === "sections") {
      setSaveModal(true)
      await updateProject(projectId, projectType, data).then(() => {
        getProject(projectId);
        sessionStorage.setItem(projectType, JSON.stringify(data));
        link && navigate(`${link}`, {state: { state }});
        setSaveModal(false)
        sessionStorage.setItem("saveError", true);
      }).catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveModal(false);
      });
    }

    link && navigate(`${link}`, {state: { state }});
  };

  useEffect(() => {
    if (saveModal === true) {
      document.body.style.cursor='wait';
    } 
    else {
      document.body.style.cursor='default'
    }
  }, [saveModal])

  return (
    <>
    <Box sx={style}>
      <Button
        disabled={disabled}
        onClick={handleOnSubmit ? handleOnSubmit : handleOnClick}
        variant="contained"
        className={isSurvey ? `customButton-${theme}` : "customButton-green"}
      >
        {text === undefined ? "Next" : text}{" "}
        {link && <HiArrowRight style={{ marginLeft: "10px" }} />}
      </Button>
    </Box>
    <Modal 
      open={saveModal} 
      sx={{ '&  .MuiBackdrop-root': { background: "rgba(0, 0, 0, 0.2)" } }}
    >
      <Box 
        component={Paper} 
        sx={{
          position: 'absolute', 
          left: "50%",
          color: "white", 
          background: themePalette["green"]["primary"], 
          p: 1,
          px: 2,
          textAlign: "center",
        }}
      >
        <Typography>Saving changes in progress...</Typography>
      </Box>
    </Modal>
    </>
  );
};

export default NextButton;
