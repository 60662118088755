import { useState } from "react";
import { useAppContext } from "../../Context/AppContext";

import { Box, Button } from "@mui/material";

const SaveButton = ({ projectType, formData, templateNum, sectionNum, disabled, setIsSuccess }) => {
  const { updateProject, setIsChanged } = useAppContext();
  const [saveLoading, setSaveLoading] = useState(false);
  
  const projId = sessionStorage.getItem("projId");
  
  const handleSubmit = async (formData) => {
    setIsChanged(false);
    setSaveLoading(true)
    if (projectType === "projDetails") {
      sessionStorage.setItem("roles", JSON.stringify(formData["roles"]))
      await updateProject(projId, projectType, formData)
      .then(() => {
        setIsSuccess(true);
        sessionStorage.setItem("saveError", true);
        setSaveLoading(false);
      })
      .catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveLoading(false);
      });
    }
    if (projectType === "projData") {
      let arr = JSON.parse(sessionStorage.getItem("projData"))
        ? JSON.parse(sessionStorage.getItem("projData"))
        : [];
      let dict = {};
      dict[templateNum] = formData;
      console.log(dict)
      console.log(sectionNum)
      arr[sectionNum - 1] = dict;
      await updateProject(projId, projectType, arr)
      .then(() => {
        setIsSuccess(true);
        sessionStorage.setItem("saveError", true);
        sessionStorage.setItem("projData", JSON.stringify(arr));
        setSaveLoading(false);
      })
      .catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveLoading(false);
      });
    } else {
      await updateProject(projId, projectType, formData)
      .then(() => {
        setIsSuccess(true);
        sessionStorage.setItem("saveError", true);
        setSaveLoading(false);
      })
      .catch(() => {
        sessionStorage.setItem("saveError", false);
        setSaveLoading(false);
      });
    }
  };

  return (
    <>
    <Box className="flexEnd" sx={{ my: 2, mr: 2}}>
      <Button
        disabled={disabled || saveLoading}
        variant="text"
        className="customButton-text-green"
        onClick={() => {
          handleSubmit(formData);
        }}
      >
        { saveLoading ?  "Saving..." : "Save" }
      </Button>
    </Box>
    </>
  );
};

export default SaveButton;
