import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import _ from "lodash";

import { themePalette } from "../../Utils/themePalette";
import links from "../../Utils/links";
import HomeLoading from "../../Components/LoadingAnimation/HomeLoading";

const Home = () => {
  const {
    getProject,
    setActiveProject,
    activeProjectId,
    theme,
    projDetails,
    sections,
    user,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const rolesList = projDetails.roles;
  let roles = [];
  rolesList.forEach((dict) => {
    roles.push(dict["role"]);
  });

  let sectionNum = localStorage.getItem("sectionNum");

  const handleOnClick = (event) => {
    localStorage.setItem("role", event.target.id);
    rolesList.forEach((dict) => {
      if (dict["role"] === event.target.id) {
        localStorage.setItem("gender", dict["isGender"]);
      }
    });
    if (user?.role === "admin" && sectionNum === null) {
      navigate(`/consent`);
    } else if (user?.role === "admin") {
      navigate(links.find((link) => link.id === sections[sectionNum]).path);
    } else
      sectionNum === null
        ? navigate(`/login`)
        : navigate(links.find((link) => link.id === sections[sectionNum]).path);
  };

  useEffect(() => {
    if (user?.role === "admin") {
      localStorage.removeItem("sectionNum");
    } else {
      localStorage.removeItem("user");
    }
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId).then((proj) => {
        localStorage.setItem("duration", proj.projDetails.duration);
        setIsLoading(false);
      });
    }
  }, [activeProjectId]);

  return (
    <div className={`backgroundImage-${theme} center`}>
      <script>{(document.title = "Welcome")}</script>
      {isLoading ? (
        <HomeLoading />
      ) : (
        <>
          <Grid container className="centerPadding" gap={2}>
            <Grid item xs={4.5} px={4}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: themePalette[theme]?.secondary,
                }}
              >
                {projDetails?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  my: 3,
                }}
              >
                {projDetails?.description}
              </Typography>
              <Grid container>
                <Grid item xs={12} className="flexStart" sx={{ my: 1 }}>
                  {roles.length === 1 && roles[0].toLowerCase() === "na" ? (
                    <Button
                      id="NA"
                      onClick={handleOnClick}
                      variant="contained"
                      className={`customButton-${theme}`}
                    >
                      Start <BsArrowRight style={{ marginLeft: "10px" }} />
                    </Button>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sx={{mb: 2}} className="flexStart"> 
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: themePalette[theme]?.secondary,
                          }}
                        >
                          I am an/a...
                        </Typography>
                      </Grid>
                    {_.map(roles, (role) => {
                      return (
                        <Grid 
                          item 
                          md={roles.length > 1 ? 5.5 : 12} 
                          className="flexStart" 
                          key={role}
                          sx={{mb: 1}}
                        >
                          <Button
                            id={role}
                            onClick={handleOnClick}
                            variant="contained"
                            className={`customButton-${theme}`}
                          >
                            {role}
                          </Button>
                        </Grid>
                      );
                    })}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5.5}>
              <img
                src={projDetails?.graphicLink}
                alt="landing page"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default Home;
