import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import axios from "axios";
import { MD5 } from "crypto-js";

import { themePalette } from "../../Utils/themePalette";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";
import links from "../../Utils/links";
import updateFilteredUser from "../../Utils/updateFilteredUser";

const Details = () => {
  const navigate = useNavigate();
  const {
    updateUser,
    user,
    theme,
    activeProjectId,
    setActiveProject,
    getProject,
    sections,
  } = useAppContext();

  const detailObj = {
    Sex: ["Male", "Female"],
    Age: [],
    Ethnicity: ["Chinese", "Malay", "Indian", "European", "Others"],
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId).then(() => {
        setIsLoading(false);
      });
    }
  }, [activeProjectId]);

  const [formData, setFormData] = useState({
    sex: "",
    age: "",
    ethnicity: "",
    ipAddress: null,
    startTime: null,
  });

  const [toSubmit, setToSubmit] = useState(false);
  const [ageError, setAgeError] = useState(false);

  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "age" && parseInt(value) < 18) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }

    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };
  useEffect(() => {
    getIPStartTime();
  }, []);

  useEffect(() => {
    if (formData.age !== "" && formData.age < 18) {
      setAgeError(true);
      setToSubmit(false);
      return;
    }
    if (!Object.values(formData).includes("") && !ageError) {
      setToSubmit(true);
    }
  }, [formData]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    updateUser({
      currentUser: {
        ...user,
        formData,
        surveyRole: localStorage.getItem("role"),
        projId: activeProjectId,
      },
      id: user._id,
    }).then((data) => {
      updateFilteredUser(data, activeProjectId);
      localStorage.removeItem("role");
      navigate(links.find((link) => link.id === sections[0]).path);
    });
    localStorage.setItem("sectionNum", 0);
  };

  const getIPStartTime = async () => {
    const res = await axios
      .get("https://api.ipify.org?format=json")
      .catch((e) => console.log(e));
    const currentTime = new Date();
    setFormData((state) => ({
      ...state,
      ipAddress: res ? MD5(res.data.IPv4).toString() : null,
      startTime: currentTime.toISOString(),
    }));
  };

  return (
    <div className={`backgroundImage-${theme} center`}>
      <script>{(document.title = "Personal Details")}</script>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleOnSubmit} style={{ position: "absolute" }}>
          <Card
            sx={{
              px: 1,
              py: 2,
              width: "500px",
            }}
          >
            <CardContent className="flexColumn center">
              <Typography
                variant="h5"
                className="formCardHeader"
                sx={{ color: themePalette[theme]["primary"] }}
              >
                Fill in your details
              </Typography>
              <FormControl sx={{ width: "80%", my: 2 }}>
                <FormGroup>
                  {_.map(detailObj, (value, detail) => {
                    if (detail === "Sex" || detail === "Ethnicity") {
                      return (
                        <TextField
                          select
                          required
                          key={detail}
                          name={detail.toLowerCase()}
                          label={detail}
                          value={formData[detail.toLowerCase()]}
                          onChange={handleOnChange}
                          sx={{ my: 1 }}
                        >
                          {_.map(value, (item) => {
                            const itemLower = item.toLowerCase();
                            return (
                              <MenuItem
                                id={itemLower}
                                value={itemLower}
                                key={itemLower}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      );
                    }
                    return (
                      <TextField
                        required
                        key={detail}
                        name={detail.toLowerCase()}
                        label={detail}
                        type="number"
                        sx={{ my: 1 }}
                        InputProps={{ inputProps: { min: 18 } }}
                        onChange={handleOnChange}
                        error={ageError}
                        helperText={ageError ? "Age must be at least 18" : ""}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
              <Button
                disabled={!toSubmit}
                type="submit"
                variant="contained"
                className={`customButton-${theme}`}
                sx={{
                  width: "80%",
                  mt: 2,
                }}
                onClick={handleOnSubmit}
              >
                Submit
              </Button>
            </CardContent>
          </Card>
        </form>
      )}
    </div>
  );
};

export default Details;
