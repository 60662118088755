import { Box, Typography } from "@mui/material";
import _ from "lodash";

import MoreText from "./MoreText";
import "./SummaryCard.css";
import { variableMap } from "../../Utils/variableList";

const GeneralContent = ({ content, handleOnClick }) => {
    const displayOutput = (arr) => _.map(arr, (value, key) => {
      return (
        <Box key={key} className="twoColumns">
          <Typography className="summaryVariable">
            {variableMap[key]}
          </Typography>
          {typeof value === "string" &&
          value.length > 88 && key !== "graphicLink" 
          ? (
            <MoreText
              key={key}
              handleOnClick={handleOnClick}
              id={variableMap[key]}
              value={value}
            />
          ) : [
              "roles",
              "questions",
              "audio", 
              "introductions",
              "messages",
              "range",
            ].includes(key) ? (
            <Box>
              {_.map(value, (aValue, index) => {
                if (key === "range") {
                  return (
                    <Typography key={index} className="rangeText">
                      {`${index} bound: ${aValue.number} represents ${aValue.text}`}
                    </Typography>
                  );
                } else if (key === "questions") {
                  return (
                    <Typography
                      key={index}
                      sx={{
                        fontSize: "14px",
                        color: "#264653",
                      }}
                    >
                      {index + 1}. {aValue["questions"]} (
                      {aValue["lowerNum"]} - {aValue["lowerText"] + " "}
                      {aValue["upperNum"]} - {aValue["upperText"]})
                    </Typography>
                  );
                } else if (key === "audio" || key === "introductions") {
                  return(
                    <Box key={key + index}>
                      <Typography className="summaryVariable">
                        <b>
                          {index === "NA"
                            ? ""
                            : `- - - - - ${index} - - - - -`}
                        </b>
                      </Typography>
                      <Box sx={{mb: 2}}>
                        
                      { _.map(aValue, (item, count) => {
                        return(
                          <Typography key={count} className="summaryText">
                            {count + 1}. {key === "audio" ? item["name"] : item}
                          </Typography>
                        )
                      })}
                      </Box>
                    </Box>
                  )
                } else {
                  return (
                      <Typography key={index} className="summaryText">
                        {index + 1}. {aValue}
                      </Typography>
                  );
                }
              })}
            </Box>
          ) : (
            !key.includes("Link") && (
              <Typography className="summaryText" key={key+value}>{value}</Typography>
            )
          )}
        </Box>
      );
    });

  return (
    <>
      {_.map(content, (arr, role) => {
        return (
          <Box key={role}>
            <Box className="twoColumns">
              <Typography className="summaryRole">{role}</Typography>
            </Box>
            {displayOutput(arr)}
          </Box>
        );
      })}
    </>
  );
};

export default GeneralContent;
