import { useState, useEffect } from "react";

import { 
  Box, 
  Typography, 
  TextField, 
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CgAdd } from "react-icons/cg";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdModeEditOutline } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import _ from "lodash";

import "./CustomFormFields.css";

const radioOptions = {
  no: "No",
  yesOpp: "Yes (profile's gender OPPOSITE of participant's gender)",
  yesSame: "Yes (profile's gender SAME as participant's gender)"
}

const AddableRoles = ({ items, error, setError, errorText, setFormData, isEditing, haveSection }) => {

  const [currValue, setCurrValue] = useState({
    role: "", isGender: null, isGenderOpp: null
  });
  const [editIndex, setEditIndex] = useState(null);

  const onAdd = () => {
    const roles = items.filter((aItem) => (
      aItem["role"].toLowerCase() === currValue["role"].toLowerCase()
    ))
    if (roles.length !== 0) {
      setError((state) => ({
        ...state,
        roles: true,
      }));
    } else {      
      setFormData((state) => ({
        ...state,
        roles: items.concat(currValue),
      }));
      setCurrValue( {role: "", isGender: null })
    }
  };

  const onDelete = (index) => {
    if (index === 0) {
      items.shift();
    } else {
      items.splice(index, 1);
    }
    setFormData((state) => ({
      ...state,
      roles: items,
    }));
  };
  
  const onEdit = (index) => {
    setCurrValue(items[index]);
    setEditIndex(index);
  }


  useEffect(() => {
    setFormData((state) => ({
      ...state, 
      roles: items
    })) 
  }, [items])

  return (
    <Box className="flexColumn">
      <Box className="secondColumn">
        <TextField
          size="small"
          fullWidth
          error={error}
          helperText={error ? errorText : ""}
          id="roles"
          value={isEditing && haveSection ? "Text field disabled" : currValue["role"]}
          disabled={isEditing && haveSection}
          placeholder="Enter role name here"
          onChange={(event) => {
            setError((state) => ({
              ...state, 
              roles: false
            }))
            setCurrValue((state) => ({
              ...state, 
              role: event.target.value
            }))
          }}
        />
        { editIndex !== null
        ?  <Button 
            onClick={() => {
              items[editIndex] = currValue;
              setEditIndex(null);
              setCurrValue( {role: "", isGender: null })
            }}
            disabled={currValue["isGender"] === null || currValue["role"] === ""}
            sx={{color: "#264653"}}
          >
            <IoIosSave
              size={20}
              style={{pointerEvents: "none"}}
            />
          </Button> 
        : <Button 
            onClick={onAdd}
            disabled={currValue["isGender"] === null || currValue["role"] === ""}
            sx={{color: "#264653"}}
          >
            <CgAdd
              size={20}
              style={{pointerEvents: "none"}}
            />
          </Button>
        }
      </Box>
      <Box>
      <Typography className="variable" sx={{ fontSize:"14px", pt:"9px", width:"350px"}}>
          Are the profiles for this role categorised into genders?
      </Typography>
      <RadioGroup
        row
        sx={{flexDirection: "column", alignItems:"flex-start"}}
        className="secondColumn"
        value={ currValue["isGender"] === null ? null
              : !currValue["isGender"] ? radioOptions["no"]
              : currValue["isGenderOpp"] ? radioOptions["yesOpp"]
              : radioOptions["yesSame"]
        }
      >
          {_.map(Object.values(radioOptions), (option) => {
            return (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio size="small" />}
                label={option}
                labelPlacement="end"
                name="isGender"
                disabled={isEditing && haveSection}
                sx={{ ".MuiFormControlLabel-label": { fontSize: "14px", color: "#264653"} }}
                onChange={(event) => {
                  setCurrValue((state) => ({
                    ...state, 
                    isGender: event.target.value.includes("Yes") ? true : false,
                    isGenderOpp: event.target.value.includes("OPPOSITE") ? true : false
                  }))
                }}
              />
            );
          })}
        </RadioGroup>
      </Box>
      {items.length > 0 &&
        <ol className="customList">
        {_.map(items, (aItem, index) => {
            return (
              <li key={aItem["role"]}>
              <Box
                className="spaceBetween"
                sx={{maxWidth: "400px"}}
              >
                <Typography 
                  sx={{
                    fontSize:"14px",
                    color: "#264653"
                  }}
                >
                  {aItem["role"]}: &nbsp;
                  { !aItem["isGender"] ? radioOptions["no"]
                  : aItem["isGenderOpp"] ? radioOptions["yesOpp"]
                  : radioOptions["yesSame"]}
                </Typography>
                <Box sx={{minWidth: "100px"}}>
                { !isEditing && 
                  <>
                    <Button 
                      id={index} 
                      disabled={editIndex===index}
                      onClick={() => onDelete(index)}
                      sx={{minWidth: "10px", mx: 1}}
                    >
                      {editIndex !== index ? <RiDeleteBin6Fill size={15}  className="customIcon"/> : <RiDeleteBin6Fill size={15}  className="customIcon-transparent"/>}
                    </Button>
                    <Button 
                      id={index} 
                      onClick={() => onEdit(index)}
                      sx={{minWidth: "10px"}}
                    >
                      <MdModeEditOutline size={15} className="customIcon" />
                    </Button>
                  </>
                }
                </Box>
              </Box>
              </li>
            );
          })}
        </ol>
      }
    </Box>
  );
};

export default AddableRoles;

