import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import {
  BsInfoCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";

import { themePalette } from "../../Utils/themePalette";
import ConfirmDialog from "../../Components/Dialog/ConfirmDialog";
import SuccessAlert from "../../Components/SnackBar/SuccessAlert";

const ChangePassword = () => {
  const { updatePassword, user } = useAppContext();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const popperOpen = Boolean(anchorEl);
  const [error, setError] = useState({
    requirement: false,
    isDifferent: false,
  });
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [mask, setMask] = useState({
    newPassword: true,
    confirmPassword: true,
  });

  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setError(false);
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleOnClick = () => {
    const newPw = formData["newPassword"];
    const confirmPw = formData["confirmPassword"];

    if (
      newPw.length < 8 ||
      !/[A-Z]/.test(newPw) ||
      !/[a-z]/.test(newPw) ||
      !/[0-9]/.test(newPw) ||
      !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPw)
    ) {
      setError((state) => ({
        ...state,
        requirement: true,
      }));
      return;
    }
    if (newPw !== confirmPw) {
      setError((state) => ({
        ...state,
        isDifferent: true,
      }));
      return;
    }
    if (!error["isDifferent"] && !error["requirement"]) {
      setConfirmOpen(true);
    }
  };

  const submitPassword = () => {
    updatePassword({
      currentUser: {
        ...user,
        formData,
      },
      id: user._id,
    });
    setConfirmOpen(false);
    setAlertOpen(true);
  };

  const handleMask = (name, upDown) => {
    setMask((state) => ({
      ...state,
      [name]: upDown === "up" ? true : false,
    }));
  };

  useEffect(() => {
    setFormData({
      newPassword: "",
      confirmPassword: "",
    });
  }, [alertOpen]);

  return (
    <div>
      <script>{(document.title = "Change Password")}</script>
      <Typography variant="h5" className="projectHeader" sx={{ m: 2 }}>
        Change Password
      </Typography>
      <Card sx={{ overflowX: "auto" }}>
        <CardContent className="cardPadding">
          <FormControl>
            <Box className="twoColumns">
              <Typography className="variable">
                New Password
                <Button
                  sx={{ minWidth: "10px", color: "#C59D5F" }}
                  onMouseEnter={(event) => {
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }}
                  onMouseLeave={() => setAnchorEl(null)}
                >
                  <BsInfoCircle />
                </Button>
              </Typography>
              <Popper open={popperOpen} anchorEl={anchorEl}>
                <Box
                  sx={{
                    backgroundColor: themePalette["green"]["primaryLight"],
                    color: themePalette["green"]["primary"],
                    p: 2, pr: 3,
                    border: `solid 1px ${themePalette["green"]["primary"]}`,
                    borderRadius: "4px",
                    fontSize: "14px",
                    maxWidth: 500,
                    whiteSpace: "pre-line",
                  }}
                >
                  <b>Password Requirements</b>
                  <ul>
                    <li>
                      Must be <b>more than 8 characters</b>
                    </li>
                    <li>
                      Must contain at least one <b>uppercase letter</b>
                    </li>
                    <li>
                      Must contain at least one <b>lowercase letter</b>
                    </li>
                    <li>
                      Must contain at least one <b>number</b>
                    </li>
                    <li>
                      Must contain at least one <b>symbol</b>
                    </li>
                  </ul>
                </Box>
              </Popper>
              <Box className="secondColumn">
                <TextField
                  required
                  size="small"
                  name="newPassword"
                  type={mask["newPassword"] ? "password" : "text"}
                  value={formData["newPassword"]}
                  fullWidth
                  onChange={handleOnChange}
                  error={error["requirement"]}
                  helperText={
                    error["requirement"]
                      ? "Password does not meet the requirements"
                      : ""
                  }
                />
                <Button
                  sx={{ color: themePalette["green"]["primary"] }}
                  onMouseDown={() => handleMask("newPassword", "down")}
                  onMouseUp={() => handleMask("newPassword", "up")}
                >
                  {mask["newPassword"] ? (
                    <BsFillEyeFill size={20} />
                  ) : (
                    <BsFillEyeSlashFill size={20} />
                  )}
                </Button>
              </Box>
            </Box>
            <Box className="twoColumns">
              <Typography className="variable">Confirm Password</Typography>
              <Box className="secondColumn">
                <TextField
                  required
                  size="small"
                  name="confirmPassword"
                  type={mask["confirmPassword"] ? "password" : "text"}
                  value={formData["confirmPassword"]}
                  fullWidth
                  onChange={handleOnChange}
                  error={error["isDifferent"]}
                  helperText={
                    error["isDifferent"] ? "Passwords do not match" : ""
                  }
                />
                <Button
                  sx={{ color: themePalette["green"]["primary"] }}
                  onMouseDown={() => handleMask("confirmPassword", "down")}
                  onMouseUp={() => handleMask("confirmPassword", "up")}
                >
                  {mask["confirmPassword"] ? (
                    <BsFillEyeFill size={20} />
                  ) : (
                    <BsFillEyeSlashFill size={20} />
                  )}
                </Button>
              </Box>
            </Box>
          </FormControl>
        </CardContent>
      </Card>
      <Box className="flexEnd" sx={{ pt: 3 }}>
        <Button
          variant="contained"
          className="customButton-green"
          onClick={handleOnClick}
        >
          Change Password
        </Button>
      </Box>
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        handleConfirm={submitPassword}
        text="Are you sure you want to change your password?"
        header="Change Password?"
      />
      <SuccessAlert
        isSuccess={alertOpen}
        setIsSuccess={setAlertOpen}
        text="Password changed successfully"
      />
    </div>
  );
};

export default ChangePassword;
