import { useState } from "react";

import { Button, Box, Typography } from "@mui/material";
import _ from "lodash";

import TemplateCard from "../../Components/TemplateCard/TemplateCard";
import SampleDialog from "../../Components/SampleDialog/SampleDialog";

const sampleList = [
    "1_Landing Page", 
    "2_Login",
    "3_Instruction", 
    "4_Profile", 
    "5_Description", 
    "6_Audio", 
    "7_Rank", 
    "8_Chatbox"
]
const themeList = ["Blue", "Brown", "Green", "Pink", "Yellow"]

const Themes =  () => {

    const [open, setOpen] = useState(false);
    const [theme, setTheme] = useState("Green");
    const [imageIndex, setImageIndex] = useState(0);

    const handleExpandImage = (index, theme) => {
        setOpen(true);          
        setTheme(theme);
        setImageIndex(index);
    }

    return (
        <div>
            <script>{document.title="Themes Samples"}</script>
            <Box className="spaceBetween">
                <Typography 
                    variant="h5" 
                    className="projectHeader"
                    sx={{m: 2}}
                >
                    THEME SAMPLES
                </Typography>
                <Button 
                    variant="contained"
                    className="customButton-green"
                    onClick={() => window.close()}
                >
                    Close
                </Button>
            </Box>
            {_.map(themeList, (theme) => {
                return(
                    <TemplateCard
                        key={theme}
                        title={theme}
                        imageList={sampleList}
                        imagePath={`Theme/${theme.toLowerCase()}/Samples`}
                        handleExpandImage={handleExpandImage}
                    />
                )
            })
            }
            <SampleDialog 
                open={open}
                setOpen={setOpen}
                imageList={sampleList}
                imageIndex={imageIndex}
                imagePath={`Theme/${theme.toLowerCase()}/Samples`}
            />
        </div>

    )
}

export default Themes;