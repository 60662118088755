import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

import NextButton from "../../Components/NavButton/NextButton";
import { themePalette } from "../../Utils/themePalette";
import "./InstructionPage.css";
import Loading from "../../Components/LoadingAnimation/LoadingAnimation";

const InstructionPage = ({ link, type }) => {
  const {
    theme,
    data,
    activeProjectId,
    setActiveProject,
    getProject,
    user,
    sections,
    getRandomPhotos,
    getRandomAudioIntro,
    updateUser,
  } = useAppContext();
  const sectionNum = localStorage.getItem("sectionNum");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUserLoaded, setFilteredUserLoaded] = useState(false);
  const filteredUser = JSON.parse(localStorage.getItem("filteredUser"));

  useEffect(() => {
    if (filteredUser) {
      setFilteredUserLoaded(true);
    }
  }, [filteredUser]);

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId);
      if (type === "audio") {
        getRandomAudioIntro(activeProjectId, {
          sectionNum,
          templateNo: 4,
          type,
          role: filteredUser?.surveyRole,
          gender: filteredUser?.sex,
        }).then((data) => {
          const { randomAudioIntro } = data;
          localStorage.setItem(
            "randomAudioIntro",
            JSON.stringify(randomAudioIntro)
          );
          setIsLoading(false);
        });
      } else if (type === "intro") {
        getRandomAudioIntro(activeProjectId, {
          sectionNum,
          templateNo: 5,
          type: "introductions",
          role: filteredUser?.surveyRole,
          gender: filteredUser?.sex,
        }).then((data) => {
          const { randomAudioIntro } = data;
          localStorage.setItem(
            "randomAudioIntro",
            JSON.stringify(randomAudioIntro)
          );
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (filteredUser !== undefined && type === "attractive-profile") {
      console.log(filteredUser);
      getRandomPhotos(activeProjectId, {
        sectionNum,
        type,
        role: filteredUser?.surveyRole,
        gender: filteredUser?.sex,
      }).then((data) => {
        localStorage.setItem(
          "profileDetails",
          data ? JSON.stringify(data?.newArr) : JSON.stringify([])
        );
        localStorage.setItem(
          "profileImg",
          data ? JSON.stringify(data?.randomImage) : JSON.stringify([])
        );
        updateUser({
          currentUser: {
            ...user,
            userResponse: {
              ...filteredUser.userResponse,
              [`profileDetails_${sectionNum + 1}`]: JSON.parse(
                localStorage.getItem("profileDetails")
              ),
              [`profileImg_${sectionNum + 1}`]: JSON.parse(
                localStorage.getItem("profileImg")
              ),
            },
            projId: activeProjectId,
          },
          id: user._id,
        });
        setIsLoading(false);
      });
    }
  }, [filteredUserLoaded, activeProjectId]);

  return (
    <div className={`backgroundImage-${theme} center`}>
      <script>{(document.title = "Instruction")}</script>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box className="instruction">
            <Box className="center" sx={{ width: "300px", p: "30px" }}>
              <img
                src={require(`../../Assets/Theme/${theme}/instruction.svg`)}
                style={{ width: "100%" }}
                alt="instruction"
              />
            </Box>
            <Card
              sx={{
                background: themePalette[theme]?.["primary"],
                color: "#FFFFFF",
                mx: 30,
                width: "80%",
              }}
            >
              <CardContent>
                <Box className="center">
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Instruction
                  </Typography>
                </Box>
                <Box className="center" pt={1.5}>
                  <Typography className="textCenter">
                    {
                      data?.[sectionNum]?.[sections[sectionNum]][
                        filteredUser?.surveyRole
                      ]?.instruction
                    }
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            <Box className="flexEnd" sx={{ py: 3, width: "80%" }}>
              <NextButton isSurvey={true} link={link} />
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default InstructionPage;
