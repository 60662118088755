import { memo } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import _ from "lodash";

import { themePalette } from "../../Utils/themePalette";
import "./HomeSample.css";

const HomeSample = ({ theme, title, description, roleList, graphic }) => {
  return (
    <div className={`sampleBackground-${theme}`}>
      <Box className="flexEnd" sx={{ mb: 5, m: 1 }}>
      </Box>
      <Grid container className="center" sx={{ mt: 6, px: 2 }}>
        <Grid item xs={5} sx={{ px: 1 }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              color: themePalette[theme]["secondary"],
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "7px",
              my: 1,
            }}
          >
            {description}
          </Typography>
          <Grid container gap={1}>
            <Grid item xs={12} className="flexStart" sx={{ my: 0.5 }}>
              { roleList[0]?.toLowerCase() !== "na" &&
                <Typography
                  sx={{
                    fontSize: "8px",
                    fontWeight: "bold",
                    color: themePalette[theme]["secondary"],
                  }}
                >
                  I am an/a...
                </Typography>
              }
            </Grid>
            {_.map(roleList, (role) => {
              return (
                <Grid item xs={5} className="flexStart" key={role}>
                  <Button
                    id={role}
                    variant="contained"
                    className={`customButton-${theme}`}
                    sx={{ fontSize: "6px", p: 0.5}}
                  >
                    { role?.toLowerCase() === 'na' ? "Start" : role }
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={5.5}>
          <img src={graphic} alt="landing page" style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </div>
  );
};
export default memo(HomeSample);
