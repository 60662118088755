import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";

import { Box, Button, Typography } from "@mui/material";
import { MdSpaceDashboard } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { FiLogOut } from "react-icons/fi";
import { RiLockPasswordFill } from "react-icons/ri";
import _, { set } from "lodash";

import "./NavBar.css";

const navList = {
  "/overview" : {
    name: "Overview",
    icon: <MdSpaceDashboard />,
  },
  "/projects" : {
    name: "Projects",
    icon: <BsCardChecklist />,
  },
  "/participants" : {
    name: "Participants",
    icon: <HiUserGroup />,
  },
  "/password" : {
    name: "Change Password",
    icon: <RiLockPasswordFill />,
  }
};

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currLocation = location.pathname.split("/")[1]
  const { logoutUser, user, isChanged, setIsChanged } = useAppContext();
  const [tab, setTab] = useState(navList["/" + currLocation].name);

  useEffect(() => {
    setTab(navList["/" + currLocation].name);
  }, [location]);
  
  const handleNavigate = (nextLoc, type) => {
    // handle discard change for forms when page change location 
    if (isChanged) {
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (!confirmLeave) return;
    }
    if (type === "logout") {
        logoutUser(user._id);
        sessionStorage.clear();
    }
    navigate(nextLoc);
    setIsChanged(false);
  }

  // Handle discard change for forms when page reload
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isChanged) {
        event.preventDefault();
        event.returnValue = '';
        const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
        if (!confirmLeave) return;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    };
  }, [isChanged])


  return (
    <>
      <div id="NavBar">
        <Box mt={12}>
          {_.map(navList, (item, key) => {
            return (
              <Button
                key={item.name}
                className="navBarButton"
                sx={{
                  background: tab.toLowerCase().includes(item.name.toLowerCase())
                    ? "#1d353f"
                    : "#264653",
                }}
                onClick={() => handleNavigate(key, "navigate")}
              >
                <Box className="center" sx={{ fontSize: "25px", m: 2 }}>
                  {item.icon}
                </Box>
                <Typography sx={{ pl: 1 }}>{item.name}</Typography>
              </Button>
            );
          })}
        </Box>
        <Box className="logoutPosition">
          <Button
            variant="contained"
            className="logoutButton"
            onClick={() => handleNavigate("/aLogin", "logout")}
          >
            <FiLogOut size={20} style={{ margin: "10px 10px 10px 0px" }} />
            <Typography>Logout</Typography>
          </Button>
        </Box>
      </div>
    </>
  );
};

export default NavBar;
