import { useAppContext } from "../../Context/AppContext";

import { Box, Typography } from "@mui/material";

import PrevButton from "../../Components/NavButton/PrevButton";

const Error404 = ({ text }) => {
  const { theme } = useAppContext();

  return (
    <div className={`backgroundImage-${theme} center`}>
      <Box
        className="center"
        sx={{
          height: "100vh",
          flexDirection: "column",
          py: 5,
        }}
      >
        <img 
          src={require("../../Assets/error404.png")}
          height="50%"
          alt="Image by pch.vector on Freepik"
        />
        <Typography variant="h3" my={1}>Oops! Something's wrong.</Typography>
        <Typography variant="h6" mb={3}>{text}</Typography>
        <PrevButton text="Return to Home" link="/" isSurvey={true} />
      </Box>
    </div>
  );
};

export default Error404;

