import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import NavBar from "../Components/NavBar/NavBar";
import GetWindowDim from "../Utils/GetWindowDim";
import NavBarMobile from "../Components/NavBar/NavBarMobile";


const DashboardLayout = () => {
  const isDesktop = GetWindowDim();

  return (
    <Box
      sx={{
        backgroundColor: "#F0F4F8",
        p: "25px 25px 50px 25px",
        minHeight: "100vh",
      }}
    >
      {isDesktop ? <NavBar /> : <NavBarMobile />}
      <Box sx={{ ml: isDesktop ? "250px" : "80px"}}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
