import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";

import { Button, Paper, Popper } from "@mui/material";

import { themePalette } from "../../Utils/themePalette";

const InstructionMobile = ({ type }) => {
  const {
    theme,
    activeProjectId,
    setActiveProject,
    getProject,
    user,
    data,
    sections,
  } = useAppContext();
  const sectionNum = localStorage.getItem("sectionNum");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId);
    }
  }, [activeProjectId]);

  const filteredUser = user.projects.filter(
    (obj) => obj.projId === activeProjectId
  );

  return (
    <div className="flexStart">
      <Button
        variant="contained"
        className={`customButton-${theme}`}
        sx={{
          "&.MuiButton-contained:hover": {
            background: themePalette[theme]["primary"],
          },
        }}
        onClick={(event) => {
          setAnchorEl(anchorEl ? null : event.currentTarget);
          setOpen(!open);
        }}
      >
        Instruction
      </Button>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <Paper
          sx={{
            backgroundColor: themePalette[theme]["primaryLight"],
            color: themePalette[theme]["primary"],
            p: 2,
            border: `solid 1px ${themePalette[theme]["primary"]}`,
            fontSize: "14px",
            textAlign: "center",
            maxWidth: "400px",
            whiteSpace: "pre-line",
            mt: 1,
          }}
        >
          {data.length !== 0 && (type === "audio" || type === "written")
            ? data[sectionNum][sections[sectionNum]][filteredUser[0]?.surveyRole]
                ?.ratingInstruction
            : data[sectionNum][sections[sectionNum]][filteredUser[0]?.surveyRole]
                ?.instruction}
        </Paper>
      </Popper>
    </div>
  );
};

export default InstructionMobile;
