import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import _ from "lodash";

import { themePalette } from "../../Utils/themePalette";

const Register = () => {
  const {
    theme,
    registerUser,
    setActiveProject,
    activeProjectId,
    getProject,
    sendEmail,
  } = useAppContext();
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [error, setError] = useState({ name: false, email: false });
  const [apiError, setApiError] = useState(false);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (event) => {
    setError({ name: false, email: false });
    setApiError(false);
    setFormData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOnSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (formData.name === "" && formData.email === "") {
      setError({ name: true, email: true });
      setTimeout(() => {
        setError({ name: false, email: false });
      }, 3000);
      return;
    }
    if (formData.email === "") {
      setError((state) => ({ ...state, email: true }));
      setTimeout(() => {
        setError({ email: false, name: false });
      }, 3000);
      return;
    }
    if (formData.name === "") {
      setError((state) => ({ ...state, name: true }));
      setTimeout(() => {
        setError({ email: false, name: false });
      }, 3000);
      return;
    }
    try {
      const userData =  await registerUser({
        name: formData.name,
        email: formData.email,
        password: "123456",
        projId: activeProjectId,
      });
      const { data } = userData;
      if (userData.status === 401 || userData.status === 400) {
        setSuccess(false);
      }
      if (userData.status === 200 || userData.status === 201) {
        if (data?.user === undefined) {
          console.log(data.userAlreadyExists.email);
          const filteredProj = data.userAlreadyExists.projects.find(
            (obj) => obj.projId === activeProjectId
          );
          await sendEmail({
            email: data.userAlreadyExists.email,
            name: data.userAlreadyExists.name,
            otp: filteredProj.otp,
            projId: activeProjectId,
            type: "start",
          });
        } else {
          await sendEmail({
            email: data.user.email,
            name: data.user.name,
            otp: data.otp,
            projId: activeProjectId,
            type: "start",
          });
        }
        setSuccess(true);
        setIsLoading(false);
      }
    } catch (error) {
      setApiError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setActiveProject();
    if (activeProjectId !== "") {
      getProject(activeProjectId);
    }
  }, [activeProjectId]);

  return (
    <div className={`backgroundImage-${theme} center`}>
      <script>{(document.title = "Register")}</script>
      <Card
        className="absoluteCenter"
        sx={{
          px: 1,
          py: 2,
          minWidth: "500px", 
          width: "50%",
          maxWidth: "700px",
        }}
      >
        <CardContent className="flexColumn center">
          <Typography
            variant="h5"
            className="formCardHeader"
            sx={{
              color: themePalette[theme]["primary"],
              "&.MuiTypography-root": { mb: 1 },
            }}
          >
            Register
          </Typography>
          <Typography
            sx={{
              color: themePalette[theme]?.["secondary"],
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            Enter your name and email address to register.
          </Typography>
          {_.map(["Name", "Email"], (detail) => {
            return (
              <Box key={detail} className="twoColumns">
                <Typography
                  className="variable secondColumn"
                  sx={{ minWidth: 100 }}
                >
                  {detail}
                </Typography>
                <TextField
                  required
                  error={error[detail.toLowerCase()]}
                  name={detail.toLowerCase()}
                  label={`Enter your ${detail.toLowerCase()}`}
                  type={detail === "Email" ? "email" : "text"}
                  onChange={handleOnChange}
                  sx={{ my: 1, width: "300px"}}
                />
              </Box>
            );
          })}
          { apiError &&
             <Alert
             severity={success ? "success" : "error"}
             sx={{ width: "80%" }}
           >
             <AlertTitle sx={{ fontWeight: "bold" }}>
               Failed to register
             </AlertTitle>
              Something is wrong. Please try again later.
           </Alert>
          }
          {success !== null && (
            <Alert
              severity={success ? "success" : "error"}
              sx={{ width: "80%" }}
            >
              <AlertTitle sx={{ fontWeight: "bold" }}>
                {success ? "Registered Successfully" : "Failed to register"}
              </AlertTitle>
              {success
                ? "An email with the OTP is sent to your email"
                : "Please try again with a different email"}
            </Alert>
          )}
          <Button
            disabled={formData["name"] === "" || formData["email"] === "" || isLoading }
            type="submit"
            variant="contained"
            className={`customButton-${theme}`}
            sx={{
              px: 2,
              mt: 2,
            }}
            onClick={handleOnSubmit}
          >
            { isLoading ? "Registering... Please wait" : "Submit"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Register;
