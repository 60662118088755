const links = [
  { id: 1, path: "/attractive-instruction" },
  { id: 2, path: "/attractive-rate-instruction" },
  { id: 3, path: "/rank-instruction" },
  { id: 4, path: "/audio-instruction" },
  { id: 5, path: "/intro-instruction" },
  { id: 6, path: "/chat-instruction" },
  { id: 7, path: "/complete" },
  { id: 8, path: "/*" },
];

export default links;
