import { useState } from "react";
import { useAppContext } from "../../Context/AppContext";

import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import { MdFileUpload } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";

import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import "./CustomFormFields.css";

const UploadFiles = ({
  items,
  setFormData,
  variable,
  accept,
  templateNum,
  style,
  role,
  sectionNum,
  gender
}) => {
  const { uploadFiles, deleteSingleResource } = useAppContext();
  const projId = sessionStorage.getItem("projId");

  let fileLink = "";
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({isError: false, message: ""});


  const uploadFile = async (event) => {
    setError({isError: false, title: "", message: ""});
    setIsLoading(true);
    if (event.target.files !== undefined && event.target.files.length > 0 ) {
      const filename = event.target.files[0].name
      if (variable === "audio") {
        let delimiterCnt = 0;
        for (let i=0; i < filename.length; i++) {
          if ( filename[i] === "_") {
            delimiterCnt += 1
            if (delimiterCnt === 1 && filename[i+1] !== 'R') {
              setError({isError: true, message: "Invalid File Name"});
              setIsLoading(false);
              return;
            }
          }
        }
        if (delimiterCnt !== 2) { 
          setError({isError: true, message: "Invalid File Name"});
          setIsLoading(false);
          return;
        }
      }
      try {
        fileLink = await uploadFiles(`${projId}_${sectionNum}_${templateNum}_${filename}_${role}`,event.target.files[0]);
        if (variable === "audio") {
          setFormData((state) => ({
            ...state,
            [role]: {
              ...state[role],
              [variable]: {
                ...state[role][variable], 
                [gender]: items.concat({
                  name: event.target.files[0].name, 
                  link: fileLink
                }),
              }}
          }));
        } else {
          setFormData((state) => ({
            ...state,
            [role]: {
              ...state[role],
              [gender]: {
                ...state[role][gender], 
                [variable]: items?.concat({
                  name: event.target.files[0].name, 
                  link: fileLink
                }),
              }
            },
          }));
        }   
      } catch (error) {
        setError({isError: true, message: "API upload failed"});
      }
    }
    setIsLoading(false);
  };

  const onDelete = async (index) => {
    setIsLoading(true);
    await deleteSingleResource(
      `${projId}_${sectionNum}_${templateNum}_${items[index]}_${role}`
    );
    setIsLoading(false);
    if (index === 0) {
      items.shift();
    } else {
      items.splice(index, 1);
    }

    if (variable === "audio") {
      setFormData((state) => ({
        ...state,
        [role]: {
          ...state[role],
          [variable]: {
            ...state[role][variable], 
            [gender]: items
          }}
      }));
    } else {
      setFormData((state) => ({
        ...state,
        [role]: {
          ...state[role],
          [gender]: {
            ...state[role][gender], 
            [variable]: items,
          }
        },
      }));
    };
  }

  return (
    <Box className="flexColumn">
      <Box className="secondColumn" style={style}>
        <Button
          variant="contained"
          className="customButton-green"
          component="label"
          disabled={isLoading}
        >
          <MdFileUpload size={20} style={{ marginRight: 5 }} />
          Choose a file to upload
          <input type="file" accept={accept} hidden onChange={uploadFile} />
        </Button>
        {isLoading && <LoadingAnimation size="1rem" marginLeft={"1rem"} />}
      </Box>
      { variable === "audio" && error.message === "Invalid File Name" &&
        <Alert severity="error">
          <AlertTitle sx={{ fontWeight: "bold" }}>Upload Failed - Invalid File Name</AlertTitle>
          Please ensure the filename format is correct: <b>{'<Name>_R<Recording No.>_<Role>'}</b><br />E.g. Jane_R1_Employer
        </Alert>
      }
      { error.message === "API upload failed" && 
         <Alert severity="error">
         <AlertTitle sx={{ fontWeight: "bold" }}>Upload Failed</AlertTitle>
            Failed to upload image. Please try again later.
       </Alert>
      }
      
      {items?.length > 0 && 
        <ol className="customList" style={{marginTop: "8px"}}>
          {_.map(items, (file, index) => {
            if (file !== undefined) {
              const name = file["name"];
              return (
                <li key={role + gender + index + name}>
                  <Box className="spaceBetween">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#264653",
                      }}
                    >
                    {name}
                    </Typography>
                    <Button id={index} onClick={() => onDelete(index)}>
                      <RiDeleteBin6Fill
                        size={15}
                        style={{
                          color: "#264653",
                          pointerEvents: "none",
                        }}
                      />
                    </Button>
                  </Box>
                </li>
              );
            }
          })}
        </ol>
      }
    </Box>
  );
};

export default UploadFiles;
