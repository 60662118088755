import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import _ from "lodash";

import "./ProjectForm.css";
import ProfileForm from "./ProfileForm";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import UploadFiles from "../CustomFormFields/UploadFiles";


const T1Profile = ({ roles, roleDict }) => {
  const { submitFormData, getProject } = useAppContext();
  const projId = sessionStorage.getItem("projId");
  const sectionNum = sessionStorage.getItem("sectionNum");

  const [formData, setFormData] = useState({});
  const [expanded, setExpanded] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProject(projId).then((project) => {
      const { data } = project;
      _.map(roleDict, (aRole) => {
        formatData(data, aRole.role, aRole.isGender);
        formatCategorise(data, aRole.role, aRole.isGender);
      });
      setIsLoading(false);
    });
  }, []);

  const formatData = (data, aRole) => {
    setFormData((state) => ({
      ...state,
      [aRole]: {
        instruction: data[sectionNum] 
        ? data[sectionNum]?.[1][aRole]?.instruction 
        : ""
      },
    }));
    setExpanded((state) => ({
      ...state,
      [aRole]: {},
    }));
  };

  const formatCategorise = (data, aRole, isGender) => {
    const genderList = isGender ? ["Male", "Female"] : ["NA"];
    _.map(genderList, (gender) => {
      _.map(_.range(1, 5), (num) => {
        setFormData((state) => ({
          ...state,
          [aRole]: {
            ...state[aRole],
            [gender]: {
              ...state[aRole]?.[gender],
              image: data[sectionNum] ? data[sectionNum]?.[1][aRole][gender]?.image : [],
              [num]: {
                optionName: data[sectionNum]
                  ? data[sectionNum]?.[1][aRole]?.[gender][num].optionName
                  : "",
                description: data[sectionNum]
                  ? data[sectionNum]?.[1][aRole]?.[gender][num].description
                  : "",
                attributes: data[sectionNum]
                  ? data[sectionNum][1][aRole]?.[gender][num].attributes
                  : [],
              },
            },
          },
        }));
        setExpanded((state) => ({
          ...state,
          [aRole]: {
            ...state[aRole],
            [gender]: {
              ...state[aRole][gender],
              [num]: false,
            },
          },
        }));
      });
    });
  };

  useEffect(() => {
    submitFormData(formData);
  }, [formData]);

  if (isLoading) {
    return (
      <div className={`background-blue center`}>
        <LoadingAnimation />
      </div>
    );
  }

  return _.map(roles, (aRole, index) => {
    return (
      <Box key={aRole}>
        {aRole.toLowerCase() !== "na" && (
          <Typography sx={{ color: "#264653", mt: 2}}>
            Role: <b>{aRole}</b>
          </Typography>
        )}
        <Card sx={{ overflowX: "auto"}}>
          <CardContent>
            <Box className="twoColumns">
              <Typography 
                className="variable" 
                sx={{ width: "350px", pl: roleDict[index]["isGender"] ? 0 : 1}}
              >
                Instruction 
              </Typography>
              <Box 
                className="secondColumn"
                sx={{ width: "350px", pl: roleDict[index]["isGender"] ? 0 : 1}}
              >
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={5}
                  id={aRole}
                  value={formData[aRole]?.instruction}
                  onChange={(event) => {
                    setFormData((state) => ({
                      ...state,
                      [aRole]: {
                        ...state[aRole],
                        instruction: event.target.value,
                      },
                    }));
                  }}
                />
              </Box>
            </Box>
            {_.map(
              roleDict[index]["isGender"] ? ["Male", "Female"] : ["NA"],
              (gender) => {
                return (
                  <Box className="flexColumn" sx={{ m: 1, mt: 3 }} key={gender}>
                    <Typography className="variable">
                      <b>{gender === "NA" ? "" : gender}</b>
                    </Typography>
                    <Box className="twoColumns" sx={{my: 3}}>
                    <Box>
                      <Typography className="variable" sx={{ width: "350px" }}>
                        Upload Image<br />
                        (At least 4 images)<br/>
                      </Typography>
                      <Typography className="variable-subtitle">
                        (in .jpg, .jpeg, .png)
                      </Typography>
                    </Box>
                    <Box className="secondColumn">
                      <UploadFiles
                        items={formData[aRole]?.[gender]?.["image"]}
                        setFormData={setFormData}
                        variable="image"
                        accept=".png, .jpg, .jpeg"
                        templateNum={1}
                        role={aRole}
                        sectionNum={sectionNum}
                        gender={gender}
                      />
                    </Box>
                    </Box>
                    {_.map(_.range(1, 5), (num) => {
                      return (
                        <Accordion
                          disableGutters
                          key={num}
                          onChange={() =>
                            setExpanded((state) => ({
                              ...state,
                              [aRole]: {
                                ...state[aRole],
                                [gender]: {
                                  ...state[aRole][gender],
                                  [num]: !expanded[aRole][gender][num],
                                },
                              },
                            }))
                          }
                        >
                          <AccordionSummary className="accordionSummary">
                            <Typography className="accordionTitle">
                              <IoIosArrowForward
                                style={{
                                  marginRight: 10,
                                  rotate: expanded[aRole][gender][num]
                                    ? "90deg"
                                    : "none",
                                }}
                              />
                              OPTION {num}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ProfileForm
                              id={num}
                              gender={gender}
                              role={aRole}
                              formData={formData}
                              setFormData={setFormData}
                              templateNum={1}
                              sectionNum={sectionNum}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                );
              }
            )}
          </CardContent>
        </Card>
      </Box>
    );
  });
};

export default T1Profile;
