export const SET_THEME = "SET_THEME";

export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const GET_PROJECT_BEGIN = "GET_PROJECT_BEGIN";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";

export const GET_RANDOM_DATA_BEGIN = "GET_RANDOM_DATA_BEGIN";
export const GET_RANDOM_DATA_SUCCESS = "GET_RANDOM_DATA_SUCCESS";
export const GET_RANDOM_DATA_ERROR = "GET_RANDOM_DATA_ERROR";

export const GET_ALL_PROJECTS_BEGIN = "GET_ALL_PROJECTS_BEGIN";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";

export const GET_USERS_BY_PROJID_BEGIN = "GET_USERS_BY_PROJID_BEGIN";
export const GET_USERS_BY_PROJID_SUCCESS = "GET_USERS_BY_PROJID_SUCCESS";

export const CREATE_PROJECT_BEGIN = "CREATE_PROJECT_BEGIN";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";

export const SET_EDIT_PROJECT = "SET_EDIT_PROJECT";
export const SET_CREATE_PROJECT = "SET_CREATE_PROJECT";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const SET_SECTION_NO = "SET_SECTION_NO";

export const DELETE_PROJECT_BEGIN = "DELETE_PROJECT_BEGIN";
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR";

export const DELETE_SINGLE_RESOURCE_BEGIN = "DELETE_SINGLE_RESOURCE_BEGIN";
export const DELETE_SINGLE_RESOURCE_ERROR = "DELETE_SINGLE_RESOURCE_ERROR";

export const DELETE_USERS_BEGIN = "DELETE_USERS_BEGIN";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";

export const DELETE_ALL_USERS_BEGIN = "DELETE_ALL_USERS_BEGIN";
export const DELETE_ALL_USERS_ERROR = "DELETE_ALL_USERS_ERROR";

export const EDIT_PROJECT_BEGIN = "EDIT_PROJECT_BEGIN";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR";

export const SUBMIT_FORM_DATA = "SUBMIT_FORM_DATA";

export const PUBLISH_PROJECT_BEGIN = "PUBLISH_PROJECT_BEGIN";
export const PUBLISH_PROJECT_SUCCESS = "PUBLISH_PROJECT_SUCCESS";

export const NEXT_SECTION = "NEXT_SECTION";
export const PREV_SECTION = "PREV_SECTION";

export const UPLOAD_FILES_BEGIN = "UPLOAD_FILES_BEGIN";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_ERROR = "UPLOAD_FILES_ERROR";

export const UPDATE_PROJECT_BEGIN = "UPDATE_PROJECT_BEGIN";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";

export const READ_CSV_BEGIN = "READ_CSV_BEGIN";
export const READ_CSV_SUCCESS = "READ_CSV_SUCCESS";
export const READ_CSV_ERROR = "READ_CSV_ERROR";

export const SEND_EMAIL_BEGIN = "SEND_EMAIL_BEGIN";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";

export const SET_ORIGINAL_STATE = "SET_ORIGINAL_STATE";

export const LOGOUT_USER = "LOGOUT_USER";

export const SET_ISCHANGED = "SET_ISCHANGED";
