import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";

import { Box, Typography } from "@mui/material";
import _ from "lodash";

import OverviewTable from "../../Components/Tables/OverviewTable";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { themePalette } from "../../Utils/themePalette"

const Overview = () => {
  const {
    projects,
    getProject,
    getUsersByProjId,
    setCreateProject,
    users,
    getAllProjects,
  } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const currentProjId = sessionStorage.getItem("oProjId");
  const sectionsString = sessionStorage.getItem("sections");
  const [projectId, setProjectId] = useState(currentProjId);

  // handle search bar
  const options = [];
  _.map(projects, (project) => {
    const { projDetails } = project;
    options.push({id: project._id, title: projDetails.title});
  });

  const handleSearchChange = async (event, value) => {
    if (value) {
      setProjectId(value.id);
      await handleOnClick(value.id); // Call handleOnClick with the new value
    }
  };

  const handleOnClick = async (value) => {
    const projId = value;

    setIsLoading(true);
    setCreateProject(projId);
    sessionStorage.setItem("oProjId", projId);

    await getProject(projId).then((proj) => {
      const { data, sections, projDetails } = proj;
      sessionStorage.setItem("data", JSON.stringify(data));
      sessionStorage.setItem("sections", JSON.stringify(sections));
      let roles = [];
      projDetails.roles.forEach((dict) => {
        roles.push(dict["role"]);
      });
      sessionStorage.setItem("role", roles);
    });

    await getUsersByProjId(projId);
    setIsLoading(false);
  };

  // handle data
  let sections = null;
  if (sectionsString) {
    try {
      sections = JSON.parse(sectionsString);
    } catch (error) {
      console.log("Error parsing string sections");
    }
  }

  const combineProfiles = (details, images) => {
    console.log(details, images)
    if (!details || !images) return "";

    return details
      .map((detail, index) => {
        const image = images[index];
        return `${image?.name}|"${detail?.optionName}"`;
      })
      .join("; ");
  };

  const displayData = [];
  _.map(users, (user) => {
    const data = {};
    data["_id"] = user["_id"];
    const userProj = user?.projects;

    if (userProj.length > 0) {
      for (let i = 0; i < userProj.length; i++) {
        if (
          userProj[i].projId === projectId &&
          userProj[i].userResponse
        ) {
          const userResponse = userProj[i].userResponse;

          const profileDetailKeys = Object.keys(userResponse).filter((key) =>
            key.startsWith("profileDetails_")
          );
          const profileImgKeys = Object.keys(userResponse).filter((key) =>
            key.startsWith("profileImg_")
          );
          
          profileDetailKeys.forEach((detailKey, index) => {
            const imgKey = profileImgKeys[index];
            const imageDetails = combineProfiles(
              userResponse[detailKey],
              userResponse[imgKey]
            );

            data[`imageDetails_${index}`] = imageDetails;
          });
          _.map(userResponse, (arr, section) => {
            if (
              !section.startsWith("profileDetails_") &&
              !section.startsWith("profileImg_")
            ) {
              const tempArr = section.split("_");
              const sect = tempArr[tempArr.length - 1];
              for (const [key, value] of Object.entries(arr)) {
                data[`section${sect}_${key}`] = value;
              }
            }
          });
        }
      }
    }

    displayData.push(data);
  });

  
  useEffect(() => {
    getAllProjects();
    if (projectId) {
      setCreateProject(projectId);
      getProject(projectId).then((proj) => {
        sessionStorage.setItem("data", JSON.stringify(proj?.data));
        sessionStorage.setItem("sections", JSON.stringify(proj?.sections));
        sessionStorage.setItem("role", proj?.projDetails.roles["0"]?.["role"]);
      });
      getUsersByProjId(projectId);
    }
  }, []);

  return (
    <Box m={2}>
      <script>{(document.title = "Overview")}</script>
      <Typography variant="h5" className="projectHeader">
        OVERVIEW
      </Typography>
      <Typography color={themePalette.green.secondary}>Please select a project using the search bar</Typography>
      <SearchBar
        handleSearchChange={handleSearchChange}
        value={options.filter((aOption) => aOption.id === projectId)[0]}
        options={options}
        disableClearable={true}
      />
      { projectId &&
      <OverviewTable
        displayData={displayData}
        projectId={projectId}
        sections={sections}
        isLoading={isLoading}
      />
      }
     
    </Box>
  );
};

export default Overview;
