import { useAppContext } from "../../Context/AppContext";

import { Box, Card, CardContent, Grid } from "@mui/material";

import ChatHeader from "./ChatHeader";
import ChatMessageArea from "./ChatMessageArea";
import Messages from "./Messages";
import MessageSelected from "./MessageSelected";
import { themePalette } from "../../Utils/themePalette";
import ProfileInfo from "./ProfileInfo";
import GetWindowDim from "../../Utils/GetWindowDim";
import "./Chatbox.css";

const ChatTemplate = ({
  selectMessage,
  setSelectMessage,
  link,
  firstCandidate,
  lastCandidate,
  title,
}) => {
  const { theme } = useAppContext();

  const receiver = link.includes("q2") ? "candidate 1" : "candidate 2";

  return (
    <Card sx={{ mx: 5 }}>
      <Grid container>
        <Grid item xs={12} sm={4} lg={3}sx={{borderRight: "solid 1px #D9D9D9" }}>
        <ProfileInfo
          receiver={receiver}
          firstCandidate={firstCandidate}
          lastCandidate={lastCandidate}
        />
        </Grid>
        <Grid item xs={12} sm={8} lg={9}>
            <ChatHeader
              receiver={receiver}
              firstCandidate={firstCandidate}
              lastCandidate={lastCandidate}
            />
            <Box id="MessageArea" >
              <CardContent>
                {selectMessage === null ? (
                  <Messages
                    setSelectMessage={setSelectMessage}
                    theme={themePalette[theme]["primary"]}
                    themeHover={themePalette[theme]["primaryLight"]}
                    title={title}
                  />
                ) : (
                  <MessageSelected
                    selectMessage={selectMessage}
                    setSelectMessage={setSelectMessage}
                    theme={themePalette[theme]["primary"]}
                  />
                )}
              </CardContent>
            </Box>
            <CardContent sx={{ borderTop: "1.5px solid #D9D9D9" }}>
              <ChatMessageArea theme={themePalette[theme]["primary"]} />
            </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ChatTemplate;
